import { gql } from "apollo-boost";

export const GET_ALL_TRANSACTIONS = gql`
    query ($start_date: String!, $end_date: String!){
  cardconnect_transaction_by_business(start_date:$start_date end_date:$end_date){
    id
    retref
    order_reference
    respcode
    void
    amount
    items{
      name
      base_amount
      tax_amount
      count
      amount
    }
    setlstat
    type
    name
    time
    response {
      transaction_id
            token
      expiry
      name
      batchid
      retref
      avsresp
      respproc
      amount
      resptext
      authcode
      respcode
      merchid
      cvvresp
      respstat
      emvTagData
      tokenExpired
      orderid
      entrymode
      bintype
      binInfo{
        product
        subtype
      }
      error
      code
      session
    }
    
  }
}
`

export const VOID_TRANSACTION = gql`
  mutation ($transactionId: Int!){
    void_transaction(transactionId:$transactionId){
      success
      message
    }
  }
`;

export const RESEND_RECEIPT = gql`
  mutation ($email: String!, $phone: String!, $transaction_id: Int!){
           resend_receipt(email:$email,  phone:$phone transaction_id: $transaction_id){
    success
      message
    }
  }
`