import { gql } from "apollo-boost";

export const GET_TERMINALS = gql`
    query {
        get_terminals{
            id 
            name
            hsn
        }
    }

`