import { gql } from "apollo-boost";

export const PROCESS_CHARGE = gql`
    mutation ($order_reference: String!, $hsn: String!, $items: [itemInput!]!){
        process_charge(input:{
            order_reference:$order_reference
            hsn:$hsn
            items: $items
        }){
    success
    data{
      transaction_id
            token
      expiry
      name
      batchid
      retref
      avsresp
      respproc
      amount
      resptext
      authcode
      respcode
      merchid
      cvvresp
      respstat
      emvTagData
      tokenExpired
      orderid
      entrymode
      bintype
      binInfo{
        product
        subtype
      }
      error
      code
      session
    }
  }
}
`