import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';

import './ChargeHistory.scss';
import Table from '../../../shared/Table/Table';
import IColumn from '../../../shared/Table/models/IColumn';
import { GraphqlService } from '../../../../services/graphql.service';
import { GET_ALL_TRANSACTIONS, RESEND_RECEIPT, VOID_TRANSACTION } from '../../../../graphql/queries/transactions';
import IRow from '../../../shared/Table/models/IRow';
import moment from 'moment';
import IMenuOption from '../../../../models/IMenuOption';
import Helper from '../../../../Helper/Helper';
import MessageBox from '../../../shared/MessageBox/MessageBox';
import LoadSpinner from '../../../shared/LoadSpinner/LoadSpinner';
import { GET_ALL_BUSINESS_INFO } from '../../../../graphql/queries/business';
import Swal from 'sweetalert2';
import { Business, PrintReceipt, printReceipt } from '../../../../services/printer.service';

const ChargeHistory = () => {


    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [type, setType] = useState<'alert' | 'confirmation'>('alert')
    const [reference, setReference] = useState<any>();
    const [rows, setRows] = useState<IRow[]>(null as any);
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState<string[]>([]);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [validResend, setValidResend] = useState(false);
    const [business, setBusiness] = useState<{name: string, phone: string}>({name: '', phone: ''})
    const [resend, setResend] = useState<{data: any, open: boolean}>({
        data: null,
        open: false
    });

    const loadBusinessInfo = async () => {
        try {
            let data: any = await GraphqlService.SendMultipleQuery(GET_ALL_BUSINESS_INFO) || [];
            setBusiness({
                name: data[2]?.name,
                phone: data[1]?.phone
            })
        } catch (ex) {
            
        }
    }

    async function load(evt?: {complete: () => void, startDate: string, endDate: string}) 
    {
        if (evt)
        {
            setDateRange([evt.startDate, evt.endDate]);
        }
        try {
            let data = await GraphqlService.SendQuery(GET_ALL_TRANSACTIONS, {start_date: new Date(evt?.startDate || dateRange[0]), end_date: new Date(evt?.endDate || dateRange[1]).addDays(1)}) as any[];
            
            let temp: IRow[] = data.map(x => 
                
                {
                    x.setlstat = x.setlstat || 'Pending';
                    let result: IRow = [
                        [
                            {label: x.id, type: 'text', value: x.id}
                        ],
                        [
                            {label: x.order_reference, type: 'text', value: x.order_reference}
                        ],
                        [
                            {label: x.setlstat, type: 'text', value: x.setlstat}
                        ],
                        [
                            {label: moment(+x.time).format('MM/DD/YYYY'), type: 'text', value: x.time},
                            {label: moment(+x.time).format('hh:mm:ss A'), type: 'text', value: x.time}
                        ],
                        [
                            {label: `${new Intl.NumberFormat('en-US',
                            { style: 'currency', currency: 'USD' }
                          ).format(x.amount)}`, type: 'text', value: x.amount}
                        ]
                    ];

                    result.menuOpened = false;
                    result.data = x;
                    return result;
                }
                
            );
            setRows(temp);
            evt?.complete();
            return true;
        } catch (ex) {
            // console.log('ex', ex.message);
            if (!rows)
                setRows([]);
            setOpen(true);
            setTitle('Error');
            setMessage(ex.message);
            setType('alert');
            evt?.complete();
            return false;
        }
    }

    const [useEffectCalled, setUseEffectCalled] = useState(false);
 
    useEffect(() => {


        if (!useEffectCalled)
            loadBusinessInfo()
        setUseEffectCalled(true);
        setValidResend((email.trim().length == 0 ? phone.length == 12 : (Helper.Validators.IsValidEmail(email) && (phone.length == 0 || phone.length == 12) )));

    }, [email, phone])



    const handlePrintReceipt = (obj: any) => {

        Swal.fire({
            icon: 'question',
            title: 'Print Receipt',
            text: 'Please select an option',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'REGULAR PRINT',
            denyButtonText: 'POS PRINT',
            cancelButtonText: 'Cancel'
        })
        .then((d) => {
            if (d.isConfirmed)
            {
                const MAX_LENGTH = 20;
        const aligned = (left: string, right: string) => {
            return `<span>${left}</span><span>${right}</span>`;
        }


        const _items = obj.items.map((x: any, i: number, []) => {
            let p = `<p style='font-weight: bold;'>${x.count}x ${x.name}</p>`
            let pamount = `<p style='display: flex; justify-content: space-between;'>${aligned('Amount', '$' + Number(x.base_amount).toFixed(2))}</p>`
            let ptax = `<p style='display: flex; justify-content: space-between;'>${aligned('Tax', '$' + Number(x.tax_amount).toFixed(2))}</p>`
            let psubtotal= `<p style='display: flex; justify-content: space-between;'>${aligned('Subtotal', '$' + Number(x.amount).toFixed(2))}</p>`

            let div = `
                <div style='margin-bottom: 6px;'>
                    ${p}
                    ${pamount}
                    ${ptax}
                    ${psubtotal}
                    <p style='text-align: center;'>-------------------------------</p>
                <div>
            `
            return div;
        });
        const body = `
        <h2 style='text-align: center;'>${business.name}</h2>
        <h3 style='text-align: center; margin-bottom: 12px;'>${business.phone}</h3>
        <p style='text-align: center;'>***</p>
        <p style='margin-bottom: 6px'>Transaction: ${obj.id}</p>
            <p style='font-weight: bold; margin-bottom: 12px;'>Items</p>
            ${_items.join('')}
            <p style='display: flex; justify-content: space-between; margin-bottom: 0;'>${aligned('Total', '$' + obj.items.sumBy((x: any) => x.amount).toFixed(2))}</p>
            <p style='margin:8px 0; text-align: center;'>-----------------------------------</p>
            <p style='margin-bottom: 8px;'>${obj.cardtype} **${obj.last4}</p>
            <p style='margin-bottom: 8px;'>${obj.name}</p>
            <p style='margin-bottom: 8px;'>${obj.subtype}</p>
            
            <p style='text-align: center;'>***</p>
            <p style='text-align: center;'>***</p>

            <p style='text-align: center;'>THANK YOU!</p>
            <p style='text-align: center;'>***</p>
        `

        let d = `<style> html,body { margin: 0; padding: 0; } p, h2, h3 { margin: 0 16px 0 4px; } .mb { margin-bottom: 6px; } .mb-2 { margin-bottom: 12px; } </style><div style='padding: 0 8px 8px 8px;'>${body}</div>`
            var mywindow = window.open('PRINT', '_blank');
            

            // mywindow?.document.appendChild(style)
            
        mywindow?.document.write(d);
        mywindow?.print();
            }
            else if (d.isDenied)
            {
                let b = new Business({name: business.name, phone: business.phone});
                let pr = new PrintReceipt({
                    business: b,
                    cardtype: obj.cardtype,
                    items: obj.items,
                    id: obj.id,
                    last4: obj.last4,
                    name: obj.name,
                    subtype: obj.subtype,
                    time: obj.time || new Date(),
                    order_reference: obj.order_reference || '',
                    received_by: obj.received_by || ''
                });
                printReceipt(pr);
            }
        })
        
        
        
    }

    const columns: IColumn[] = [
        {
            label: 'TRANSACTION #',
            orderBy: 'DESC',
            active: false
        },
        {
            label: 'ORDER REFERENCE',
            orderBy: 'DESC',
            active: false,
            hideOnSmall: true
        },
        {
            label: 'STATUS',
            orderBy: 'DESC',
            active: false
        },
        {
            label: 'TIME',
            orderBy: 'DESC',
            active: false,
            hideOnSmall: true
        },
        {
            label: 'AMOUNT',
            orderBy: 'DESC',
            active: false,
        }
    ]

    const menuOptions: IMenuOption[] = [
        {
            icon: Helper.Icons.IconDetails,
            label: 'See Transaction Details',
            action: (obj: any) => {
                showDetail(obj);

            }
        },

        {
            icon: Helper.Icons.IconCreateInvoice,
            label: 'Send Receipt',
            action: (obj: any) => {
                setResend({
                    open: true,
                    data: obj
                });
                
                setEmail('');
                setPhone('');


            }
        },
        {
            icon: Helper.Icons.IconReceipt,
            label: 'Print Receipt',
            // condition: (obj => obj.setlstat == 'Pending'),
            action: (obj: any) => {
                
                let cardtype = '';
                
                switch(obj.response.binInfo?.product){
                    case 'A': cardtype = 'Amex'; break;
                    case 'D': cardtype = 'Discover'; break;
                    case 'M': cardtype = 'Mastercard'; break;
                    case 'V': cardtype = 'Visa'; break;
                    default: cardtype = 'Non-co-branded card'; break;
                  }
                handlePrintReceipt({
                    id: obj.id,
                    last4: obj.response.token.substring(obj.response.token.length - 4, obj.response.token.length),
                    cardtype,
                    subtype: obj.response.binInfo?.subtype || '',
                    name: obj.name,
                    items: obj.items
                });
            }
        },
        {
            icon: Helper.Icons.IconDelete,
            label: 'Void Transaction',
            // condition: (obj => obj.setlstat == 'Pending'),
            action: (obj: any) => {
                
                setOpen(true);
                setMessage('Are you sure you want to void this transaction?');
                setTitle('Void Transaction #' + obj.id);
                setType('confirmation')
                setReference({data: obj, key: 'void'})
            }
        }
    ]


    const voidTransaction = async (id: number) => {
        try {
            setLoading(true);
            
            let data = await GraphqlService.SendMutation(VOID_TRANSACTION, {transactionId: id});

            if (!await load())
                return setLoading(false);
            
            setLoading(false);

            setType('alert')
            setMessage(data.message);
            // if ()
            setTitle(data.success ? 'Message' : 'Error');
            setOpen(true);
        } catch (ex) {
            setLoading(false);
            setType('alert')
            setMessage(ex.message);
            setTitle('Error');
            setOpen(true);
        }
    }

    const handleConfirm =(reference: any) => {
        // console.log(reference);
        if (reference)
        {
            if (reference.key == 'void')
                voidTransaction(reference.data.id);
        }
    }


    const handlePropertyChanged = (value: string, key: string) => {
        if (key == 'phone')
            setPhone(Helper.Masks.ValidPhone(value));
        else
            setEmail(value);
    }


    const showDetail = (obj: any) => {
        let temp = {...obj};

        temp.items.forEach((x: any) => {
            delete x.__typename;
        })

        delete temp.__typename;
        delete temp.response;

        temp.total_amount = new Intl.NumberFormat('en-US',
        { style: 'currency', currency: 'USD' }).format(temp.items.sumBy((x: any) => x.amount));
        setTitle('Transaction Details');
        setMessage(JSON.stringify(temp, null, 2));
        setType('alert');
        setOpen(true);
    }
    const handleResend = async (evt: any) => {
        let transaction = resend.data;
        
        setResend({
            open: false,
            data: null
        });
        try {
            let obj: any = {
                email,
                phone,
                transaction_id: transaction.id
            }

            // console.log(JSON.stringify(obj));
            setLoading(true);
            let data = await GraphqlService.SendMutation(RESEND_RECEIPT, obj);
            await load();
            setLoading(false)
        } catch (ex) {
            setLoading(false);
            setTitle('Error');
            setMessage(ex.message);
            setType('alert');
            setOpen(true);

        }
    }

    return (
        <div className='charge-history-component'>
            {loading && <LoadSpinner />}
            {resend.open && <div className="modal">
                    <div className="modal-content">
                        <h4 className='form-title mb-3'>RESEND RECEIPT FOR T-{resend.data.id}</h4>
                        
                        <div className='form-group mb-3'>
                            <label htmlFor="email">EMAIL*</label>
                            <input id='email' name='email' value={email} onChange={(evt) => handlePropertyChanged(evt.target.value, 'email')} className='form-field' type="email"/>
                        </div>
                         <div className='form-group mb-3'>
                            <label htmlFor="phone">PHONE*</label>
                            <input id='phone' name='phone' value={phone} onChange={(evt) => handlePropertyChanged(evt.target.value, 'phone')} className='form-field' type="text"/>
                        </div>

                        <div className='d-flex justify-content-between mt-4'>
                            <button className='btn' onClick={(evt) => setResend({open: false, data: null})}>CANCEL</button>
                            <button onClick={(evt) => handleResend(evt)} className='btn btn-orange' disabled={!validResend}>SEND</button>
                        </div>
                    </div>
                </div>}
                    
                    
            <MessageBox onConfirmClicked={(reference) => handleConfirm(reference)} type={type} isOpen={open} setIsOpen={setOpen} message={message} title={title} reference={reference} />
            <div>
                    <Table selectionType='range' onRowClicked={(obj) => showDetail(obj)} title='TRANSACTIONS' menuOptions={menuOptions} columns={columns} rows={rows} onLoadRequested={(evt) => load(evt)} />
                    <div style={{padding: '1rem', position: 'sticky', bottom: 0, background: '#FFF', zIndex: 1}}>
                        <h4 className='text-right'>Total: {new Intl.NumberFormat('en-US',
                            { style: 'currency', currency: 'USD' }
                          ).format(rows?.map(x => x.data).sumBy(x => x.amount) || 0)}</h4>
                    </div>
            </div>
        </div>
    )
}

export default ChargeHistory;