import React from 'react';


const NewInvoice = () => {
    return (<div>
        

        <form action="" className='small-form'>

            <h4 className='form-title'>CHARGE HISTORY</h4>
        </form>
    </div>)
}

export default NewInvoice;