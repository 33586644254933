import { gql } from 'apollo-boost';
import React, { useEffect, useRef, useState } from 'react';
import { UIItem, UIPresetItem } from '../../../../classes/classes';
import { GET_ALL_BUSINESS_INFO, UPDATE_BUSINESS_INFO, UPDATE_BUSINESS_PROFILE, UPDATE_BUSINESS_SETTINGS, UPDATE_PASSWORD } from '../../../../graphql/queries/business';
import { CREATE_CHARGE_PRESET, DELETE_CHARGE_PRESET, GET_CHARGES_PRESET, UPDATE_CHARGE_PRESET } from '../../../../graphql/queries/item';
import Helper from '../../../../Helper/Helper';
import { GraphqlService } from '../../../../services/graphql.service';
import LoadSpinner from '../../../shared/LoadSpinner/LoadSpinner';
import MessageBox from '../../../shared/MessageBox/MessageBox';



import './Settings.scss';

const Settings = () => {
    

    const [useEffectCalled, setUseEffectCalled] = useState(false);
    const [items, setItems] = useState<UIPresetItem[]>([]);
    const [oItems, setOItems] = useState<UIPresetItem[]>([]);
    const [isValidToAdd, setIsValidToAdd] = useState(false);
    const [originalItems, setOriginalItems] = useState<UIPresetItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const inpRef = useRef<any>();

    const [tabIndex, setTabIndex] = useState(0);


    // ACCOUNT INFORMATION

    const [businessName, setBusinessName] = useState('');

    const [business, setBusiness] = useState<any>({});
    const [oBusiness, setOBusiness] = useState<any>({});

    

    const [password, setPassword] = useState<{old_password: string, new_password: string, confirm_password: string, expanded: boolean}>({
        old_password: '',
        new_password: '',
        confirm_password: '',
        expanded: false
    });
    
    const [contactInfo, setContactInfo] = useState<any>({});
    const [oContactInfo, setOContactInfo] = useState<any>({});
    
    const [settings, setSettings] = useState<any>({});
    const [oSettings, setOSettings] = useState<any>({});


    const [passwordExpanded, setPasswordExpanded] = useState(false);



    const handleUpdateInfo = async (key: 'business' | 'profile' | 'settings' | 'password') => {
        const mutations = {
            'business': UPDATE_BUSINESS_INFO,
            'profile': UPDATE_BUSINESS_PROFILE,
            'settings': UPDATE_BUSINESS_SETTINGS,
            'password': UPDATE_PASSWORD
        }

        const variables = {
            'business': {...business},
            'profile': {...contactInfo},
            'settings': {...settings},
            'password': {old_password: password.old_password, new_password: password.new_password}
        }

        try {
            setLoading(true);

            const data = await GraphqlService.SendQuery(mutations[key], variables[key]);

            if (data.success)
            {
                setLoading(false);
                setPassword({confirm_password: '', expanded: false, new_password: '', old_password: ''});
                await loadAllInfo();
            }
            else
                throw new Error(data.message);
            
        } catch (ex) {
            setLoading(false)
            setType('alert');
            setTitle('Error');
            setMessage(ex.message);
            setOpen(true);
        }

    }

    const loadAllInfo = async () => {
        try {
            let data = await GraphqlService.SendMultipleQuery(GET_ALL_BUSINESS_INFO);

            setBusiness(data[2])
            setOBusiness(JSON.clone(data[2]))

            setContactInfo(data[1])
            setOContactInfo(JSON.clone(data[1]));

            setSettings(data[0])
            setOSettings(JSON.clone(data[0]));


            
        } catch (ex) {
            setType('alert');
            setTitle('Error');
            setMessage(ex.message);
            setOpen(true);
        }
    }

    const loadItems = async () => {
        try {
            if (!loading)
                setLoading(true);
            let data = await GraphqlService.SendQuery(GET_CHARGES_PRESET) as UIPresetItem[];
            data.map(x => {x.tax = x.tax_amount > 0 ? 9.5 as any : 0; x.valid = true; x.amount = Number(Number(Number(x.base_amount) + Number(x.tax_amount)).toFixed(2))})

             data.sort((x, y) => x.name.toLocaleLowerCase().trim() > y.name.toLowerCase().trim() ? 1 : -1)
            // console.log(data);

            setLoading(false)
            setItems(data);
            setOriginalItems(JSON.clone(data));
            handleSearchChanged(searchTerm, JSON.clone(data));
        } catch (ex) {
            setLoading(false)
            
            setType('alert');
            setTitle('Error');
            setMessage(ex.message);
            setOpen(true);

        }
    }


    useEffect(() => {
        setIsValidToAdd(!items.some(x => !x.valid || x.id == -1 || x.expanded));
        if (!useEffectCalled)
        {

            loadAllInfo();
            loadItems();
        }
        setUseEffectCalled(true);
    }, [items])

    const taxes = [{name: 'No Tax\t\t0.00%', amount: 0}, {name: 'Sales Tax\t\t9.5%', amount: 9.5}];
    const handleToggleExpand = (index: number) => {
        let temp = [...items];
        if (temp[index].expanded)
            return;
        temp.map((y, i) => i != index ? y.expanded = false : y.expanded);
        temp[index].expanded = !temp[index].expanded;
        setItems(temp);
    }


    const handleNameChanged = (value: string, index: number) => {
        // console.log(value);
        let temp = [...items];
        temp[index].name = value;
        
        checkValidity(temp, index);
    }

    const handleBaseAmountChanged = (value: string, index: number) => {
        // console.log(value);
        let temp = [...items];
        temp[index].base_amount = getOnlyNumbers(value) as any;
        handleTaxChanged(temp[index].tax, index, temp);
    }


    const handleTaxAmountChanged = (value: string, index: number) => {
        // console.log(value);
        let temp = [...items];
        temp[index].tax_amount = getOnlyNumbers(value) as any;
        handleTaxChanged(temp[index].tax, index, temp, true);
    }


    const handleTaxChanged = (value: any, index: number, tempItems?: UIPresetItem[], skip?: boolean) => {
        const temp =  tempItems || [...items];
        
        if (!skip)
        {
            temp[index].tax = Number(value);
            temp[index].tax_amount = Number(((Number(temp[index].base_amount) * Number(value)) / 100).toFixed(2));
        }
        // console.log(temp[index]);
        calculateTotal(temp, index);
    }

    const getOnlyNumbers = (value: string) => {
        let newString = '';
        let decimalCount = 0;
        for (let i = 0; i < value.length; i++) {
            const char = value.substring(i, i + 1);
            if (((char >= '0' && char <= '9') || (char == '.' && !newString.includes('.'))) && decimalCount < 2)   
            {
                if (newString.includes('.'))
                    decimalCount++;
                newString += char;
            }
            
        }
        return newString == '.' ? '0.' : newString;
    }

    const calculateTotal  = (temp: UIPresetItem[], index: number) => {

        let base = temp[index].base_amount;
        let tax = temp[index].tax_amount;

        const total = Number(base) + Number(tax);
        
        temp[index].amount = Number(( Number(total?.toFixed(2))));


        checkValidity(temp, index);
    }

    const checkValidity = (temp: UIPresetItem[], index: number) => {

        let valid = temp[index].name.trim() != '' && Number(temp[index].base_amount) != 0;
        temp[index].valid = valid;
        setItems(temp);
    }

    const removeItem = (id: number) => {
        setType('confirmation');
        setTitle('Delete this Preset');
        setMessage('Are you you sure you want to delete this preset charge?');
        setRef({key: 'delete-preset', data: {id}})
        setOpen(true);
    }


    const handleSendItem = async (item: UIPresetItem) => {
        try {
            setLoading(true);
            let obj: any = {name: item.name, base_amount: +item.base_amount, tax_amount: +item.tax_amount};
            if (item.id != -1)
                obj.id = item.id;

            // console.log(JSON.stringify(obj));
            let data = await GraphqlService.SendMutation(item.id == -1? CREATE_CHARGE_PRESET : UPDATE_CHARGE_PRESET, obj);
            await loadItems();
        } catch (ex) {
            // console.log('ex', ex.message);
            setLoading(false);
        }
    }


    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('')
    const [type, setType] = useState<'alert' | 'confirmation'>('alert');
    const [ref, setRef] = useState<any>({});


    const handleConfirmation = (ref: any) => {
        if (ref)
        {
            if (ref.key == 'delete-preset')
                handleDeletePreset(ref.data.id);
        }
    }

    const handleDeletePreset = async (id: number) => {
       try {
        //    console.log('id', id);
            setLoading(true);
            await GraphqlService.SendMutation(DELETE_CHARGE_PRESET, {id});
            await loadItems();
       } catch (ex) {
           setType('alert');
           setTitle('Error');
           setMessage(ex.message);
           setOpen(true);
        setLoading(false);
       }
    }


    const isObjectFilled = (obj: any) => {
        const keys = Object.keys(obj);
        if (keys.length == 0)
            return false;
        for (let key of keys)
        {
            let value = (obj[key] || '').toString();
            if (value.trim() == '')
                return false;
            
            
        }
        return true;
    }


    const isValidPassword = () => !isObjectFilled(password) ? false : password.confirm_password == password.new_password;


    const handleSearchChanged = (value: string, original?: UIPresetItem[]) => {
        value = value || '';
        setSearchTerm(value)

        const o = JSON.clone(original || originalItems) ;
        if (originalItems.length == 0)
            return;
        if (value.trim() == '')
        {
            setItems([...o]);
        }
        else
            setItems(JSON.clone(o).filter(x => JSON.stringify(x).toLowerCase().includes(value.toLowerCase().trim())));
    }


    return (<div className='settings-component'>
        {loading && <LoadSpinner />}
        <MessageBox isOpen={open} setIsOpen={setOpen} message={message} title={title} type={type} reference={ref} onConfirmClicked={(ref) => handleConfirmation(ref)} />

        <h4 className='col-12 mb-3' style={{fontSize: '2rem', fontWeight: 600}}>SETTINGS</h4>
            <div className='sticky-header pb-3'>
            <div className='d-flex col-12 tabs'>
                <p className={'mr-3 pb-2' + (tabIndex == 0 ? ' active' : '')} onClick={(evt) => setTabIndex(0)}>Account Information</p>
                <p className={'pb-2' + (tabIndex == 1 ? ' active' : '')} onClick={(evt) => setTabIndex(1)}>Preset Items</p>
            </div>
            </div>
            {tabIndex == 0 && <div className='col-12 col-sm-12 mb-4'>
                
                <div className='mb-3'> 
                                <button type='button' className={'btn item-header  d-flex justify-content-between col-12 expanded' + (isObjectFilled(business) ? ' valid' : '')}>
                                <h4>Business Name</h4>
                        </button>
                        <div className={"item-content col-12 d-flex justify-content-between flex-wrap px-3 pt-3 expanded"}>
                            <div className='form-group col-12 mb-3 position-relative'>
                                <label htmlFor="name">Name*</label>
                                <input name='name' id='name' value={business?.name} onChange={(evt) => setBusiness({...business, name: evt.target.value})} className='form-field' type="text"/>
                            </div>
                        
                            

                            <div className='col-12 d-flex justify-content-between mt-3'>
                                <button className="btn px-4" disabled={JSON.compare(business, oBusiness)} onClick={(evt) => setBusiness(JSON.clone(oBusiness))}>CANCEL</button>
                                <button className="btn btn-orange px-4" disabled={!isObjectFilled(business)} onClick={(evt) =>  handleUpdateInfo('business')}>UPDATE</button>
                            </div>
                        </div>
                    
                        </div>



                        <div className='mb-3'> 
                                <button onClick={(evt) => setPassword({...password, expanded: true})} type='button' className={'btn item-header  d-flex justify-content-between col-12' + (password.expanded && !isValidPassword() ? '' : ' valid') + (password.expanded ? ' expanded' : '')}>
                                <h4>Change Password</h4>
                                <h4>{password.expanded ? '-' : '+'}</h4>
                        </button>
                        <div className={"item-content col-12 d-flex justify-content-between flex-wrap px-3 pt-3"  + (password.expanded ? ' expanded' : '')}>
                            <div className='form-group col-12 mb-3 position-relative'>
                                    <label htmlFor="oldp">Old Password*</label>
                                    <input id='oldp' name='oldp' value={password.old_password} onChange={(evt) => setPassword({...password, old_password: evt.target.value})} className='form-field' type="password"/>
                                </div>
                                <div className='form-group col-12 mb-3 position-relative'>
                                    <label htmlFor="newp">New Password*</label>
                                    <input id='newp' name='newp' value={password.new_password} onChange={(evt) => setPassword({...password, new_password: evt.target.value})} className='form-field' type="password"/>
                                </div>
                                    <label htmlFor="conp">Confirm Password*</label>
                                <div className='form-group col-12 mb-3 position-relative'>
                                    <input id='conp' name='conp' value={password.confirm_password} onChange={(evt) => setPassword({...password, confirm_password: evt.target.value})} className='form-field' type="password"/>
                                </div>
                        
                            

                            <div className='col-12 d-flex justify-content-between mt-3'>
                                <button className="btn px-4" onClick={(evt) => setPassword({confirm_password: '', old_password: '', new_password: '', expanded: false})}>CANCEL</button>
                                <button className="btn btn-orange px-4" disabled={!isValidPassword()} onClick={(evt) =>  handleUpdateInfo('password')}>UPDATE</button>
                            </div>
                        </div>
                    
                        </div>
                        

                        <div className='mb-3'> 
                                <button type='button' className={'btn item-header  d-flex justify-content-between col-12 expanded' + (isObjectFilled(contactInfo) && (contactInfo?.phone?.length || 0) == 12 && Helper.Validators.IsValidEmail(contactInfo?.email || '') ? ' valid' : '')}>
                                <h4>Profile</h4>
                        </button>
                        <div className={"item-content col-12 d-flex justify-content-between flex-wrap px-3 pt-3 expanded"}>
                        <div className='form-group col-6 mb-3 position-relative'>
                                <label htmlFor="c_name">First Name*</label>
                                <input id='c_name' name='c_name' value={contactInfo?.first_name} onChange={(evt) => setContactInfo({...contactInfo, first_name: evt.target.value})} className='form-field' type="text"/>
                            </div>
                            <div className='form-group col-6 mb-3 position-relative'>
                                <label htmlFor="l_name">Last Name*</label>
                                <input id='l_name' name='l_name' value={contactInfo?.last_name} onChange={(evt) => setContactInfo({...contactInfo, last_name: evt.target.value})} className='form-field' type="text"/>
                            </div>
                            <div className='form-group col-12 mb-3 position-relative'>
                                <label htmlFor="phone">Phone*</label>
                                <input id='phone' name='phone' value={contactInfo?.phone} onChange={(evt) => setContactInfo({...contactInfo, phone: Helper.Masks.ValidPhone(evt.target.value)})} className='form-field' type="text"/>
                            </div>
                                <label htmlFor="email">Email*</label>
                            <div className='form-group col-12 mb-3 position-relative'>
                                <input id='email' name='email' value={contactInfo?.email} onChange={(evt) => setContactInfo({...contactInfo, email: evt.target.value})} className='form-field' type="text"/>
                            </div>
                        
                            

                            <div className='col-12 d-flex justify-content-between mt-3'>
                                <button className="btn px-4" disabled={JSON.compare(contactInfo, oContactInfo)} onClick={(evt) => setContactInfo(JSON.clone(oContactInfo))}>CANCEL</button>
                                <button className="btn btn-orange px-4" disabled={!isObjectFilled(contactInfo) || (contactInfo?.phone?.length || 0) != 12 || !Helper.Validators.IsValidEmail(contactInfo?.email || '')} onClick={(evt) =>  handleUpdateInfo('profile')}>UPDATE</button>
                            </div>
                        </div>
                    
                        </div>


                       <div className='mb-3'> 
                                <button type='button' className={'btn item-header  d-flex justify-content-between col-12 expanded' + (isObjectFilled(settings) ? ' valid' : '')}>
                                <h4>Settings</h4>
                        </button>
                        <div className={"item-content col-12 d-flex justify-content-between flex-wrap px-3 pt-3 expanded"}>
                            <div className='form-group col-12 mb-3 position-relative'>
                                <label htmlFor="i_text">Invoice Text*</label>
                                <textarea id='i_text' name="i_text" value={settings?.invoice_text} onChange={(evt) => setSettings({...settings, invoice_text: evt.target.value})} className='form-field'/>
                            </div>
                            <div className='form-group col-12 mb-3 position-relative'>
                                <label htmlFor="r_text">Receipt Text*</label>
                                <textarea id='r_text' name="r_text" value={settings?.receipt_text} onChange={(evt) => setSettings({...settings, receipt_text: evt.target.value})} className='form-field'/>
                            </div>
                        
                            

                            <div className='col-12 d-flex justify-content-between mt-3'>
                                <button className="btn px-4" disabled={JSON.compare(settings, oSettings)} onClick={(evt) => setSettings(JSON.clone(oSettings))}>CANCEL</button>
                                <button className="btn btn-orange px-4" disabled={!isObjectFilled(settings)} onClick={(evt) =>  handleUpdateInfo('settings')}>UPDATE</button>
                            </div>
                        </div>
                    
                        </div>
                        

            </div>}
            
            {tabIndex == 1 && <div className='col-12'>

                <div className='d-flex justify-content-end mb-3'>
                    <div style={{position: 'relative'}} className='col-4 col-sm-12'>
                        <img onClick={(evt) => inpRef.current.focus()} style={{position:'absolute', top: '50%', left: '.15rem', transform: 'translateY(-50%)', cursor: 'pointer'}} src={Helper.Icons.IconSearch} alt=""/>
                        <input className='search-input' ref={inpRef} value={searchTerm} onChange={(evt) => handleSearchChanged(evt.target.value)} type="text" placeholder='Search....' style={{width: '100%'}} />
                        <img onClick={(evt) => {handleSearchChanged('');}} style={{position:'absolute', top: '50%', right: '0.45rem', transform: 'translateY(-50%)', cursor: 'pointer'}} src={Helper.Icons.IconDelete} alt=""/>
                    </div>
                </div>
                <div className='d-flex justify-content-between mb-3 aling-items-center'>

                    <p className='text-blue fw-600'>Presets Items {items.length} of {originalItems.length}</p>
                    <button disabled={!isValidToAdd} className='btn btn-orange' onClick={(evt) => {
                        const temp = [...items];
                        temp.map(x => x.expanded = false);
                        temp.unshift(new UIPresetItem());
                        setItems(temp);
                    }}>ADD NEW ITEM</button>
                </div>

                {items.map((item, i) => 
                            <div id={'i-'+ i} key={`i-${i}`} className='mb-3'> 
                                <button type='button' className={'btn item-header blue d-flex justify-content-between col-12 '  + (item.expanded ? 'expanded' : '')  + (item.valid ? ' valid' : '')}  onClick={(evt) => handleToggleExpand(i)}>
                                <h4>{item.name}</h4>
                                <h4>${item.amount?.toFixed(2) || 0}<span className='ml-2'>{item.expanded ? '-' : '+'}</span></h4>
                        </button>
                        <div className={"item-content col-12 d-flex justify-content-between flex-wrap px-3 pt-3 " + (item.expanded ? 'expanded' : '')}>
                            <div className='form-group col-6 mb-3 position-relative'>
                                <label htmlFor="">Name*</label>
                                <input value={item.name} onChange={(evt) => handleNameChanged(evt.target.value, i)} className='form-field' type="text"/>
                            </div>
                            {item.id == -1 ? <div className='col-6'></div> : <div className='col-6 d-flex justify-content-end align-items-end'>

                            <button type='button' className='btn btn-danger' onClick={(evt) => removeItem(item.id)}>REMOVE</button>
                            </div>}
                            <div className='form-group col-6 mb-3'>
                                    <label htmlFor={"ba-" + i}>Base Amount*</label>
                                    <input id={"ba-" + i} name={"ba-" + i} value={item.base_amount} onChange={(evt) => handleBaseAmountChanged(evt.target.value, i)} className='form-field' type="text"/>

                                </div>
                                <div className='form-group col-6 mb-3'>
                                    
                                </div>
                            <div className='form-group col-6'>
                                <label htmlFor={"ta-" + i}>Tax Amount*</label>
                                <input id={"ta-" + i} name={"ta-" + i} value={item.tax_amount} onChange={(evt) => handleTaxAmountChanged(evt.target.value, i)} className='form-field' type="text"/>
                                {/* <select className='form-field' name="" id="">
                                    <option value="0">0%</option>
                                    <option value="9.5">9.5%</option>
                                </select> */}

                            </div>

                            <div className="form-group col-6">
                                <label htmlFor="tax">Tax*</label>
                                <select name="tax" id="tax" className='form-field' value={item.tax} onChange={(evt) => handleTaxChanged(evt.target.value, i)}>
                                    {taxes.map(x => <option value={x.amount}>{x.name}</option>)}
                                </select>
                            </div>
                            

                          
                            <div className='col-12 d-flex justify-content-between mt-5'>
                                <button className="btn px-4" onClick={(evt) => {setItems(JSON.clone(originalItems)); handleSearchChanged(searchTerm)}}>CANCEL</button>
                                <button className="btn btn-orange px-4" onClick={(evt) => handleSendItem(item)}>{item.id == -1 ? 'SAVE' : 'UPDATE'}</button>
                            </div>
                        </div>
                    
                        </div>
                        )}
                </div>
}
            
    </div>)
}

export default Settings;