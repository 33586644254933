import { ControlledMenu, MenuItem } from '@szhsin/react-menu';
import FlatList from 'flatlist-react';
import React, { useEffect, useRef, useState } from 'react';
import Helper from '../../../Helper/Helper';
import IMenuOption from '../../../models/IMenuOption';
import SubMenu from '../SubMenu/SubMenu';
import IColumn from './models/IColumn';
import IRow from './models/IRow';
import './Table.scss';
import { DateRangePicker, DateRange } from 'react-date-range';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Business, PrintReport, printReport, ReportData } from '../../../services/printer.service';

interface ITableProps {
    title: string,
    columns: IColumn[],
    rows: IRow[],
    onRowClicked: (data: any) => void;
    onLoadRequested?: (evt: {complete: () => void, startDate: string, endDate: string}) => void,
    menuOptions?: IMenuOption[],
    selectionType: 'single' | 'range' | 'none',
    business?: any
}

const Table = ({title, columns, rows, onLoadRequested, menuOptions, onRowClicked, selectionType, business}: ITableProps) => {


    const [isOpen, setOpen] = useState(false);
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [myColumns, setMyColumns] = useState<IColumn[]>([]);
    const [myRows, setMyRows] = useState<IRow[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchShown, setSearchShown] = useState(false);
    const [loading, setLoading] = useState(false);
    const inpRef = useRef<any>();
    const [count, setCount] = useState(0);
    const [firstTime, setFirtsTime] = useState(false);
    
    const handleLoad = (dateStart?: Date, dateEnd?: Date) => {
        if (onLoadRequested && !loading)
        {
            setLoading(true);
            onLoadRequested({startDate: moment(dateStart || selectionRange.startDate).format('YYYY/MM/DD'), endDate: moment(dateEnd ||selectionRange.endDate).format('YYYY/MM/DD'), complete: () => {
                setLoading(false)
            }});
        }
    }
    const handleRowClicked = (obj: any) => {
        onRowClicked(obj);
    }
    
    const handleColumnHeaderClick = (index: number) => {
        const temp = [...myColumns];
        const tempRows = [...myRows];
        if (temp[index].active)
            temp[index].orderBy = temp[index].orderBy == 'DESC' ? 'ASC' : 'DESC';
        else
        {
            temp.map(x => x.active = false);
            temp[index].active = true;
        }



        doSort(index, temp, tempRows)
    }

    const doSort = (index: number, temp: IColumn[] = [...myColumns], tempRows: IRow[] = [...myRows]) => {
        
        
        if (temp[index].orderBy == 'ASC')
        {
          tempRows.sort((x, y) => x[index][0].value == y[index][0].value ? 0 : x[index][0].value > y[index][0].value ? 1 : -1);
        }
        else
        {
          tempRows.sort((x, y) => x[index][0].value == y[index][0].value ? 0 : x[index][0].value > y[index][0].value ? -1 : 1);
        }

        setMyColumns(temp);
        setMyRows(tempRows);
    }

    useEffect(() => {

        // if (rows == null)
        // {
        //     setLoading(true)
        // }

        // if (rows != null && count == 0)
        // {
        //     setCount(count + 1);
        //     setLoading(false)
        // }
        if (JSON.stringify(columns) != JSON.stringify(columns) || columns.length != 0)
            setMyColumns(columns);
        
        const ordered = searchTerm.trim() == '' ? rows || []: (rows || []).filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim()));
        setMyRows(
            ordered
        );
        
        let index = myColumns.findIndex(x => x.active);
        if (index > -1)
        {
            doSort(index, myColumns, ordered);
        }


        if (!firstTime)
            handleLoad();
        setFirtsTime(true);

       
        // console.log('search', rows.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim())))

    }, [columns, rows, searchTerm])




    const [selectionRange, setSelectionRange] = useState({
        startDate: selectionType == 'single' ? new Date() :  moment().add('days', -2).toDate(),
        endDate: new Date(),
        key: 'selection',
      });


      const [tempSelectionRange, setTempSelectionRange] = useState({
        startDate: selectionType == 'single' ? new Date() :  moment().add('days', -2).toDate(),
        endDate: new Date(),
        key: 'selection',
      });


      const [pickerShown, setPickerShown] = useState(false);

      function handleSelect(ranges: any){
        // console.log(ranges);

        
        let value;
        if (moment(ranges.selection.endDate).format('YYYY/MM/DD') != moment(tempSelectionRange.endDate).format('YYYY/MM/DD') )
        {
            value = ranges.selection.endDate;
        }
        else
        {
            value = ranges.selection.startDate;
        }
            

        setTempSelectionRange({
            key: selectionRange.key,
            startDate: selectionType == 'single' ? value : ranges.selection.startDate,
            endDate: selectionType == 'single' ? value : ranges.selection.endDate
        })
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
      }

      const [value, onChange] = useState([ moment().add('days', -2).hours(0).toDate(), new Date()]);



    let interval: any = null;

    const onTouchStart = (evt: React.TouchEvent<any>, idx: number) => {

        interval = setInterval(() => {
            
            setAnchorPoint({ x: evt.touches[0].clientX, y: evt.touches[0].clientY });
            const temp = [...myRows];
            temp.map(x => x.menuOpened = false);
            temp[idx].menuOpened = true;
            setMyRows(temp);
            clearInterval(interval);
        }, 500)
    }


    const onTouchEnd = (evt: any) => {
        clearInterval(interval);
    }
    const addDay = (days: number) => {
        const startDate = moment(selectionRange.startDate).add(days, 'days').toDate();
        setSelectionRange({
            startDate: startDate,
            endDate: startDate,
            key: 'selection'
        });
        setTempSelectionRange({
            startDate: startDate,
            endDate: startDate,
            key: 'selection'
        })
        handleLoad(startDate, startDate);
        
    }

    const handlePrintReport = () => {
        console.log(myRows.map(x => x.data)[0])
        Swal.fire({
            icon: 'question',
            title: 'Print Receipt',
            text: 'Please select an option',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'REGULAR PRINT',
            denyButtonText: 'POS PRINT',
            cancelButtonText: 'Cancel'
        })
        .then((d) => {
            if (d.isConfirmed)
            {
                const aligned = (left: string, right: string) => {
                    return `<span>${left}</span><span>${right}</span>`;
                }
                const _items = myRows.map((x, i: number, []) => {
        
                    
                    let pname = `<p style='font-weight: bold;'>Transaction: #${x.data?.id}</p>`
                    let porder = `<p style='display: flex; justify-content: space-between;'>${aligned('Order Reference:', x.data?.order_reference)}</p>`
                    let ptime = `<p style='display: flex; justify-content: space-between;'>${aligned('Time:', moment(+x.data?.time).format('hh:mm:ss A'))}</p>`
                    let pamount= `<p style='display: flex; justify-content: space-between;'>${aligned('Amount', '$' + Number(x.data?.items.sumBy((x: any) => x.amount)).toFixed(2))}</p>`
        
                    let div = `
                        <div style='margin-bottom: 6px;'>
                            ${pname}
                            ${x.data?.order_reference ? porder : ''}
                            ${ptime}
                            ${pamount}
                            <p style='text-align: center;'>-------------------------------</p>
                        <div>
                    `
                    return div;
                });
                const body = `
                <h2 style='text-align: center;'>${business.name}</h2>
                <h3 style='text-align: center; margin-bottom: 12px;'>${business.phone}</h3>
                <p style='text-align: center;'>***</p>
                    <p style='font-weight: bold; margin-bottom: 12px;'>Transactions of ${moment(selectionRange.startDate).format('MM/DD/YYYY')}</p>
                    ${_items.join('')}
                    <p style='display: flex; justify-content: space-between; margin-bottom: 0;'>${aligned('Total', Helper.Masks.ValidUSMoney(myRows.sumBy((x: any) => x.data?.items.sumBy((y: any) => y.amount).toFixed(2))))}</p>
                    
                    <p style='text-align: center;'>***</p>
                    <p style='text-align: center;'>***</p>
        
                    <p style='text-align: center;'>THANK YOU!</p>
                    <p style='text-align: center;'>***</p>
                `
        
                let d = `<style> html,body { margin: 0; padding: 0; } p, h2, h3 { margin: 0 16px 0 4px; } .mb { margin-bottom: 6px; } .mb-2 { margin-bottom: 12px; } </style><div style='padding: 0 8px 8px 8px;'>${body}</div>`
                    var mywindow = window.open('PRINT', '_blank');
                    
        
                    // mywindow?.document.appendChild(style)
                    
                mywindow?.document.write(d);
                mywindow?.print();
            }
            else if (d.isDenied)
            {
                let bus = new Business({name: business.name, phone: business.phone});
                let data: ReportData[] = myRows.map(x => ({items: x.data?.items, order_reference: x.data?.order_reference, time: x.data?.time}));

                let pr = new PrintReport({
                    business: bus,
                    data,
                    date: moment(selectionRange.startDate).format('MM/DD/YYYY')
                })
                printReport(pr);
            }
        })
        
    }

    return (
        <div className='table-component'>
            {pickerShown && <div className='modal'>
                <div className='modal-content' style={{width: '100%', maxWidth: '400px'}}>
                    <div className='mx-auto' style={{width: 'fit-content'}}>
                        <DateRange
                                showDateDisplay={false}
                                showPreview={selectionType == 'range'}
                                ranges={[tempSelectionRange]}
                                onChange={handleSelect}
                            />
                    </div>
                    <div className="d-flex justify-content-between">
                        <button className="btn" onClick={(evt) => setPickerShown(false)}>CANCEL</button>

                        <button className="btn btn-blue" onClick={(evt) => { setSelectionRange({endDate: tempSelectionRange.endDate, startDate: tempSelectionRange.startDate, key: tempSelectionRange.key}); setPickerShown(false); handleLoad(tempSelectionRange.startDate, tempSelectionRange.endDate)}}>CONFIRM</button>
                    </div>
                </div>
            </div>
            
            }
            <div className='d-flex justify-content-between align-items-center flex-wrap'>
                <h4 className='form-title text-blue fw-600'>{title} {myRows.length} OF {rows?.length || 0}</h4>
                <div className='d-flex align-items-center flex-wrap'>
                   {selectionType != 'none' && <button className='btn btn-blue mr-2 mb-3 no-select' onClick={(evt) =>{ setTempSelectionRange({endDate: selectionRange.endDate, startDate: selectionRange.startDate, key:  selectionRange.key}); setPickerShown(true)}}>
                        {moment(selectionRange.startDate).format('MM/DD/YYYY')} {selectionType == 'range' && <span> - {moment(selectionRange.endDate).format('MM/DD/YYYY')}</span>} <i className="far fa-calendar-alt ml-2"></i>
                    </button>}
                    {selectionType == 'single' && <div className='d-flex'>
                        <button  className={'icon-container mr-2 mb-3 no-select'} onClick={(evt) => addDay(-1)}>
                            <i className="fas fa-chevron-left"></i>
                        </button>
                        <button  className={'icon-container mr-2 mb-3 no-select'} onClick={(evt) => addDay(1)}>
                            <i className="fas fa-chevron-right"></i>
                        </button>
                         <button  className={'icon-container mr-2 mb-3 no-select'} disabled={myRows.length == 0 || loading} onClick={(evt) => handlePrintReport()}>
                         <i className="fas fa-print"></i>
                        </button>
                    </div>}
                    <button  className={'icon-container mr-2 mb-3 no-select' + (loading ? ' spin' : '')} onClick={(evt) => handleLoad()}>
                        <img src={Helper.Icons.IconRefresh} alt=""/>
                    </button>
                    {!searchShown && <div className='icon-container mb-3 no-select' onClick={(evt) => {setSearchShown(true); inpRef?.current?.focus()}}>
                        <img src={Helper.Icons.IconSearch} alt=""/>
                    </div>}
                    <div className='mb-3 no-select' style={{transition: '.3s width linear', width: searchShown ? '14rem' : '0', overflow: 'hidden'}} >
                        <div style={{position: 'relative'}} className='width-fit-content'>

                            <img onClick={(evt) => inpRef.current.focus()} style={{position:'absolute', top: '50%', left: '.15rem', transform: 'translateY(-50%)', cursor: 'pointer'}} src={Helper.Icons.IconSearch} alt=""/>
                            <input className='search-input' ref={inpRef} value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)} type="text" placeholder='Search....'/>
                            <img onClick={(evt) => {setSearchTerm(''); setSearchShown(false)}} style={{position:'absolute', top: '50%', right: '0.45rem', transform: 'translateY(-50%)', cursor: 'pointer'}} src={Helper.Icons.IconDelete} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            <table cellPadding='0' cellSpacing='0'>
            <thead>
                <tr>
                    {myColumns.map((c, i) => <th className={(c.active  ? ' active' : '') + (c.hideOnSmall ? ' no-mobile' : '')} key={'ch-' + i} onClick={(evt) => handleColumnHeaderClick(i)}>{c.label} <i className={"fas fa-sort-down" + (c.orderBy == 'DESC' ? ' desc' : '')}></i></th>)}

                    <th></th>
                </tr>
            </thead>
            <tbody>
                
            <FlatList
                list={myRows}
                renderItem={(item: any, idx: number) => <tr onTouchStart={(evt) => onTouchStart(evt, idx)} onTouchEnd={onTouchEnd} onClick={(evt) => handleRowClicked(item.data)} className={(item.menuOpened ? 'selected' : '')} onContextMenu={(e) => {
                    // myRows.map(x => x.)
                    e.preventDefault();
                    setAnchorPoint({ x: e.clientX, y: e.clientY });
                    const temp = [...myRows];
                    temp.map(x => x.menuOpened = false);
                    temp[idx].menuOpened = true;
                    setMyRows(temp);

                }}>
                   
                    {
                item.map((x: any, idx: number) => <td className={(myColumns[idx]?.hideOnSmall ? 'no-mobile' : '')}>
                        {x.map((y: any) => <p className='text'>{y.label}</p>)}
                    </td>)}
                    <td onClick={(evt) => evt.stopPropagation()}>
                    <ControlledMenu onContextMenu={(evt) =>{evt.stopPropagation(); evt.preventDefault()}} anchorPoint={anchorPoint} isOpen={item.menuOpened} animation={false}
                        onClose={() => {
                        const temp = [...myRows];
                        temp[idx].menuOpened = false;
                        setMyRows(temp);
                    }}>
                    {menuOptions?.map(x => (x.condition ? x.condition(item.data) : true) && <MenuItem className='my-menuitem' onClick={(evt) => { if (x.action) x.action(item.data)}} style={{paddingLeft: '0'}}><img style={{marginLeft: '-1rem', marginRight: '.45rem'}} src={x.icon} alt=""/>{x.label}</MenuItem>)}
                </ControlledMenu>
                    </td>
                </tr>}
                renderWhenEmpty={() => null}
            />
                {/* {myRows.map((item: any, idx: number) => )} */}
                
            </tbody>
        </table>

   
            </div>

        
        </div>
        )
}

export default Table;