import { gql } from "apollo-boost";

export const SEND_MANUAL_TRANACTION = gql`
     mutation ($order_reference: String!, $items: [itemInput!]!, $payInfo: Payinfo!) {
    manual_input(
        input: {
        order_reference: $order_reference
        items: $items
        payinfo: $payInfo
        }
    ){
    success
    data{
      transaction_id
            token
      expiry
      name
      batchid
      retref
      avsresp
      respproc
      amount
      resptext
      authcode
      respcode
      merchid
      cvvresp
      respstat
      emvTagData
      tokenExpired
      orderid
      entrymode
      bintype
      binInfo{
        product
        subtype
      }
      error
      code
      session
    }
  }
}

`

export const SEND_CUSTOMER_PAYMENT = gql`
     mutation ($transaction_invoice_id: Int!, $payInfo: Payinfo!, $email: String!) {
        customer_payment(
        input: {
        transaction_invoice_id: $transaction_invoice_id
        payinfo: $payInfo
        email:$email
        }
    ){
        success
        message
    }
    }

`

export const SEND_ECOMMERCE = gql`
  mutation($input:String){
  eCommercePayment(input:$input){
    success
    data{
      transaction_id
      code
    }
    error
    message
  }
}
`;