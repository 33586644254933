import React, { useEffect, useState } from 'react';

import "./Login.scss"


import IconUser from '../../assets/svg/Icon_User_16px.svg';
import IconBusiness from '../../assets/svg/Icon_Business_16px.svg';
import IconLocked from '../../assets/svg/Icon_Locked_16px.svg';
import IconDelete from '../../assets/svg/Icon_Delete.svg';
import ILoginProps from './models/ILoginProps';
import Helper from '../../Helper/Helper';
import environments from '../../environment/environment';
import AuthService from '../../services/auth.service';
import LoadSpinner from '../shared/LoadSpinner/LoadSpinner';

const Login = () => {



    



    const [username, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [businessID, setBusinessID] = useState<string>('')
    const [btnEnabled, setBtnEnabled] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [wrongText, setWrongText] = useState('');
    
    

    const clear = () => {
        setUserName('')
        setPassword('')
        setBusinessID('')
    }

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        
        let data = {
            username,
            password,
            code: businessID
        }


        setLoading(true)
        // console.log('enviroment', environments.SERVER_LINK)
        fetch(environments.SERVER_LINK + '/auth', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({username, password, code: businessID})
        })
        .then(d => d.json())
        .then((result) => {
            setLoading(false)
            if (result.success)
            {
                AuthService.TOKEN = result.token;
                environments.IFRAME_LINK = result.iframe + environments.IFRAME_OPTIONS;
                
                localStorage.setItem('token', AuthService.TOKEN);
                localStorage.setItem('iframe_link', environments.IFRAME_LINK);
                Helper.Navigation.NavigateTo('/');
            }
            else{
                setWrongText(result.message)
            }
        })
        .catch(err => {
            setLoading(false)
            setWrongText(err.message)
        })

        
    }
    
    useEffect(() => {
        setBtnEnabled(username.trim() != '' && password.trim() != '' && businessID.trim() != '')
    }, [username, password, businessID])

    return (
        <>
        {loading && <LoadSpinner />}
       <div className='login-component'>
       <div className='login-container'>
            <h4>Log In</h4>

            <form onSubmit={handleSubmit.bind(this)}>
                <div className='input-group'>
                    <img src={IconUser} className='left' />
                    <input value={username} onChange={(evt) => setUserName(evt.target.value)} id='name' name='username' placeholder='Username' />
                    {username != '' ? <img src={IconDelete} className='right'  onClick={(evt) => setUserName('')} /> : null} 
                </div>
                <div className='input-group'>
                    <img src={IconLocked} className='left' />
                    <input type='password' value={password} onChange={(evt) => setPassword(evt.target.value)} id='password' name='password' placeholder='Password' />
                    {password != '' ? <img src={IconDelete} className='right'  onClick={(evt) => setPassword('')} /> : null} 
                </div>
                <div className='input-group'>
                    <img src={IconBusiness} className='left' />
                    <input value={businessID} onChange={(evt) => setBusinessID(evt.target.value)} id='business_id' name='business_id' placeholder='Business ID' />
                    {businessID != '' ? <img src={IconDelete} className='right' onClick={(evt) => setBusinessID('')} /> : null} 
                </div>

                {wrongText && <p className='wrong'>{wrongText}</p>}
                <button disabled={!btnEnabled} type='submit'>LOG IN</button>
              
            </form>
        </div>

      
       </div>
        </>
    )
};

export default Login;