import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom';
import environments from '../../environment/environment';
import { SEND_CUSTOMER_PAYMENT, SEND_ECOMMERCE } from '../../graphql/mutations/transactions';
import { GET_INVOICE_BY_TOKEN } from '../../graphql/queries/invoice';
import { GET_ALL_TRANSACTIONS } from '../../graphql/queries/transactions';
import { GraphqlService, ObservableQueryT } from '../../services/graphql.service';
import CardInput from '../shared/CardInput/CardInput';
import IPayInfo from '../shared/CardInput/models/IPayInfo';
import LoadSpinner from '../shared/LoadSpinner/LoadSpinner';
import MessageBox from '../shared/MessageBox/MessageBox';
import IconGPAY from '../../assets/svg/GPay_Acceptance_Mark_800.png';
import IconAPAY from '../../assets/svg/Apple_Pay_Mark_RGB_041619.svg';
import IconECheck from '../../assets/svg/echeckpay_asset.svg';
import IconCPay from '../../assets/svg/cardpay_asset.svg';
// cardpay_asset.svg echeckpay_asset.svg
import logo from '../../assets/svg/logo.svg';

import './PayCharge.scss';
import { encrypt } from '../../classes/encrypt';
import { Subscription } from 'rxjs';

const PayCharge = () => {



    const [invoice, setInvoice] = useState<any>({});
    const {token} = useParams<{token: string}>();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [formShown, setFormShown] = useState(false);
    const [paid, setPaid] = useState(false);
    const [selected, setSelected] = useState(-1);
    const observarbleQuery = useRef<ObservableQueryT>();
    const observable = useRef<Subscription>();
    



    useEffect(() => {
        load();
        return () => {
            observarbleQuery.current.stopPolling();
            observable.current?.unsubscribe();
        }
    }, [])

   
    

    async function load()
    {
        setLoading(true);
        // console.log(environments.SERVER_LINK)
        try {
            // let d = await GraphqlService.SendQuery(query, {token});
            observarbleQuery.current = GraphqlService.SendQueryObservable(GET_INVOICE_BY_TOKEN, {token});
            observable.current = observarbleQuery.current.onResults.subscribe(({data, error}) => {
                setLoading(false);
                // console.log({data, error: error.message})
                if (error)
                {
                    setOpen(true);
                    setTitle('Error');
                    setMessage(error.message);
                }
                else
                    setInvoice(data);
            }, err => {
                setOpen(true);
                    setTitle('Error');
                    setMessage(err.message || err);
            })
            // console.log(d)
            
        } catch (ex) {
            setLoading(false);
            setOpen(true);
            setTitle('Error');
            setMessage(ex.message);
        }
    }
    
    

    

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');


    const sendCustomerPayment = async (payInfo: IPayInfo) => {
            setLoading(true);
            try {
                const temp: any = {...payInfo};
                delete temp.email;
                delete temp.phone;
                if(payInfo.state.includes('-')){
                    payInfo.state = '';
                }
                let body = {payInfo: temp, email: payInfo.email, phone: payInfo.phone, transaction_invoice_id: invoice?.id};
                const items =  invoice?.invoice_items.filter(x => !x.cardconnect_transaction) || [];
                const input =  {
                    "token": token,
                    "items": items.map(item => ({"id": item.id, "name": item.name, "base_amount": item.base_amount, "tax_amount": item.tax_amount, "count": item.count, "amount": item.amount, "reference": item.reference})),
                    "email": temp.email,
                    "phone": temp.phone,
                    "amount": items.sumBy(x => x.amount),
                    payinfo: payInfo
                  }
                // console.log('body', body);
                // console.log(JSON.stringify(body));
                let result = await GraphqlService.SendMutation(SEND_ECOMMERCE, {input: encrypt(input)})
                
                setLoading(false)
                if (result.success)
                {
                    setFormShown(false);
                    setPaid(true);
                    setOpen(true);
                    setTitle('Message');
                    setMessage('Thank you for your payment');
                }
                else
                {   
                    setOpen(true);
                    setMessage(result.message || 'Payment Declined');
                    setTitle('Error');
                }
            } catch (ex) {
                
                setLoading(false)
                setOpen(true);
                setMessage(ex.message);
                setTitle('Error');
            }
    }
    
    
    if (location.pathname.split('/').filter(x => x != '').length > 2 || invoice == null)
        return (<Redirect to='/404' />)


    enum Screens {
        CreditCard,
        GooglePay,
        ApplePay,
        ECheck
    }


    const PAYMENTS_OPTIONS = [
        {
            label: 'Credit Card',
            icon: IconCPay,
            value: Screens.CreditCard
        },
        {
            label: 'Apple Pay',
            icon: IconAPAY,
            value: Screens.ApplePay
        },
        {
            label: 'Google Pay',
            icon: IconGPAY,
            value: Screens.GooglePay
        },
        {
            label: 'E-Check',
            icon: IconECheck,
            value: Screens.ECheck
        }
    ]

    const getTitle = () => {
        switch (selected)
        {
            case Screens.CreditCard:
                return 'Credit Card'
            case Screens.ApplePay:
                return 'Apple Pay'
            case Screens.GooglePay:
                return 'Google Pay'
            case Screens.ECheck:
                return 'E-Check';
            default:
                return 'Payment Method';
        }
    }

    const renderBody = (showCancel = true) => {
        return <>
            <button className="btn btn-dark col-12">Google Pay</button>
            <button className="btn btn-dark mt-3 mb-2 col-12">Apple Pay</button>
            <div className='position-relative mb-4 mt-3'>
                <hr />
                <h4 className="font-16 px-2" style={{position: 'absolute', background: 'white', top: '50%', left:'50%', transform: 'translate(-50%, -50%)'}}>OR</h4>
            </div>
            <CardInput submitText={`Pay $${invoice?.invoice_items?.filter(x => !x.cardconnect_transaction).sumBy((x: any) => x.amount)?.toCurrency('')}`} zipMandatory={true} onCancelClicked={!showCancel ? null : (evt) => setFormShown(false)} onPaymentSubmitted={(payInfo) => sendCustomerPayment(payInfo)}  />
        </>
    }

    

    return (

        <div className='pay-charge-component d-flex flex-column justify-content-between flex-wrap align-items-center'>

            {formShown && <div className="modal flex-1-container">
                <div className="modal-content fade-up flex-1-container">

                    <div className="d-flex justify-content-between align-items-center mb-3">

                        <h5 className='form-title mb-0'>{getTitle()}</h5>
                        <button onClick={(evt) => {setFormShown(false); setSelected(-1)}} className='btn btn-clear fw-100'><span style={{display: 'block', transform: 'scale(2)'}}>&times;</span></button>
                    </div>

                 
                 
                    <div className="flex-1-container">

                        {renderBody()}
                    </div>

                    {/* <CardInput onCancelClicked={(evt) => false} onPaymentSubmitted={(payInfo) => sendCustomerPayment(payInfo)} /> */}
                </div>
            </div>}
            {loading && <LoadSpinner />}
            <MessageBox isOpen={open} setIsOpen={setOpen} title={title} message={message} />
            <div className="pay-header">
                <div className='d-flex align-items-center'>
                    <img src={logo} alt="" />
                    <p className="font-16 ml-3">Profuse Pay</p>
                </div>

            </div>
            <div className="col-12 mt-5">
            
            
            <div className="row align-items-start col-12">
                <div className='col-6 order-summary mw-500-1023 mx-auto-1023'>

    <div className='summary-header'>
    <div className='col-12 mb-1'>
                <p className='font-16 mb-1'>{invoice?.business?.name}</p>
                <div className='pl-2'>

                    {/* <h5 className="black-text mb-1"><span style={{fontWeight: !invoice?.name ? 500 : 'bold'}} className={!invoice?.name ? 'text-gray' : ''}>{invoice?.name || 'NO NAME'}</span> for: {invoice?.order_reference || '-'}</h5> */}
                    <h5 style={{fontSize: '40px'}}><sup style={{fontSize: '16px', fontWeight: 300}}>$</sup> <span className='fw-600'>{invoice?.amount_remaining?.toCurrency('')}</span></h5>
                </div>
            </div>
        <div className='d-flex justify-content-between '>

            {/* <h5 className='summary-title'>ORDER SUMMARY</h5> */}
            {/* {invoice?.cardconnect_transaction && <h5 className='summary-title text-blue'>PAID</h5>} */}
        </div>
       {invoice?.order_reference && <>
        <p className='text-gray font-14'>Order Reference</p>
        <p className="font-14 ">{invoice?.order_reference || '-'}</p>
       </>}
    </div>   
    <div className='p-3 mt-3'>

        {invoice?.invoice_items?.map((item: any, i: number) => 
        <div className={'d-flex' + (i == invoice?.invoice_items?.length - 1 ? '' : ' pb-4 mb-4 border-bottom-gray')}>
            <div style={{flex: '1'}}>
                <p className="font-16 black-text mb-1">{item.name}</p>
                {item.count > 1 && <p className="text-gray font-14">{item.count} <span>| ${(item.base_amount).toCurrency('')} each</span></p>}
                {item?.cardconnect_transaction && <p className="font-14 mt-2 text-blue">Paid by {item?.cardconnect_transaction?.response?.binInfo.cardType} *{item?.cardconnect_transaction?.response?.token.substr(-4)}</p>}
            </div>
            <div>
                <p className="font-16 mb-1 text-right">${Number(item.base_amount * item.count).toCurrency('')}</p>
                {item.tax_amount != 0 && <p className="text-gray text-right font-14">Tax: (${Number(item.tax_amount * item.count).toCurrency('')})</p>}
            </div>
        </div>
        )}
        
    </div>

    <div className="summary-footer">
        <div className={'d-flex'}>
            <div style={{flex: '1'}}>
                <p className="font-16  mb-3">Subtotal</p>
                {<p className=" font-16">Tax Amount</p>}
            </div>
            <div>
                <p className="font-16 mb-3 text-right">${invoice?.invoice_items?.sumBy(x => x.base_amount * x.count).toCurrency('')}</p>
                <p className="font-16 text-right">${invoice?.invoice_items?.sumBy(x => x.tax_amount * x.count).toCurrency('')}</p>
            </div>
        </div>
    </div>

    <div className='summary-footer'>

        <div className="row">
            <p className="font-18">Total</p>
            <p className="black-text font-18 text-right">${invoice?.invoice_items?.sumBy((x: any) => x.amount)?.toCurrency('')}</p>
        </div>
        {invoice?.invoice_items?.some(x => x.cardconnect_transaction) && <>
            <div className="row my-2">
                <p className="font-18">Amount Paid</p>
                <p className="black-text font-18 text-right">${invoice?.invoice_items?.filter(x => x.cardconnect_transaction).sumBy((x: any) => x.amount)?.toCurrency('')}</p>
            </div>
            <div className="row">
                <p className="font-18">Amount Due</p>
                <p className="black-text font-18 text-right">${invoice?.amount_remaining.toCurrency('')}</p>
            </div>
        </>}
        {invoice?.note &&
            <div className='mt-5'>
                <p className='font-16 font-500 mb-1'>NOTE FOR THE CUSTOMER</p>
                <p className='pl-3 font-14' style={{whiteSpace: 'pre'}}>{invoice.note}</p>
            </div>}
        {!formShown && invoice?.id != null && invoice?.amount_remaining != 0 &&
            <div className='d-flex justify-content-end mt-5'>
                <button onClick={(evt) => setFormShown(true)} className="btn btn-gray hide-1024 col-12">Input Payment Information</button>
            </div>}
        <div className='mt-3'>
            <p className="text-center text-blue font-14 mt-5">SECURED PAYMENT POWERED BY PROFUSE PAY AND CARD CONNECT</p>
        </div>
    </div>


                </div>
                <div className="col-6 order-summary p-3 hide-1023">
                    {invoice?.amount_remaining > 0 && <>
                        <h5 className='summary-title mb-3'>Payment Method</h5>
                        {renderBody(false)}
                    </>}
                    
                </div>
            </div>
            </div>



        </div>
    )
}

export default PayCharge;