export class Item
{
    name: string = '';
    base_amount: number = '' as any;
    tax_amount: number = '' as any;
    amount: number = 0;
    count: number = 1;

    constructor() {}
}

export class UIItem extends Item
{
    expanded: boolean = true;
    valid: boolean = false;
    tax: number = 0;
    constructor() { super(); }
}

export class Terminal
{
    id: number = -1;
    name: string = '';
    hsn: string = '';
}


export class PresetItem
{
    id: number = -1;
    name: string = '';
    base_amount: number = '' as any;
    tax_amount: number = '' as any;

    constructor() {}
}

export class UIPresetItem extends PresetItem
{
    id: number = -1;
    name: string = '';
    base_amount: number = '' as any;
    tax_amount: number = '' as any;
    expanded: boolean = true;
    valid: boolean = false;
    amount: number = 0;
    tax: number = '' as any;

    constructor() { super(); }
}