import IconUser from '../assets/svg/Icon_User_16px.svg';
import IconBusiness from '../assets/svg/Icon_Business_16px.svg';
import IconLocked from '../assets/svg/Icon_Locked_16px.svg';
import IconDelete from '../assets/svg/Icon_Delete.svg';
import IconSearch from '../assets/svg/Icon_Search.svg';
import IconRefresh from '../assets/svg/Icon_Refresh.svg';
import IconDetails from '../assets/svg/Icon_Details.svg';
import IconCreateInvoice from '../assets/svg/Icon_Create_Invoice.svg'
import IconReceipt from '../assets/svg/Icon_Receipt.svg';
import ProfuseLogoBlack from '../assets/img/Logo_Black.png';

interface INavigator
{
    push: (url: string) => void,
    goBack: () => void
}

export default class Helper 
{

    private static Navigator: INavigator;
    static SetNavigator(navigator: INavigator)
    {
        if (this.Navigator)
            return;
        this.Navigator = navigator;
    }

    static Session = {
        DoLogout: () => {
            Helper.Navigation.NavigateTo('/login')
            localStorage.clear();
        }
    }

    static Navigation = {
        NavigateTo: (url: string) => {
            Helper.Navigator.push(url)
        },
        GoBack: () => {
            Helper.Navigator.goBack();
        }
    }

    static Icons = {
        IconUser,
        IconBusiness,
        IconLocked,
        IconDelete,
        IconSearch,
        IconRefresh,
        IconDetails,
        IconCreateInvoice,
        IconReceipt
    }
    static Images = {
        ProfuseLogoBlack
    }

    static Masks = {
        ValidPhone: (value: string) => {
            let phone = '';
        let hcount = value.split('').filter(x => x == '-').length;
        
        value = value.replace('-', '');
        for (let i = 0; i < value.length; i++)
        {
            const char = value.substring(i, i + 1);

            if (char >= '0' && char <='9')
                phone += char;
            if (phone.length == 12)
                break;
            
            if (phone.length == 3 || phone.length == 7)
                phone += '-';
        }

        let array = phone.split('-');

        let first = array[0] || '';
        let second = array[1] || '';
        let third = array[2] || '';



        return first + ((hcount > 0 || second.length >= 1) && first.length > 2 ? '-' : '') + second + ((hcount > 1 || third.length >= 1) && second.length > 2 ? '-' : '') + third;
        },
        ValidNumbers: (value: string, maxLength?: number) => {
            let newString = '';
            for (let i = 0; i < value.length; i++) {
                const char = value.substring(i, i + 1);
                if (((char >= '0' && char <= '9')))
                {
                    newString += char;
                }
                if (maxLength != null && newString.length == maxLength)
                    return newString;
                
            }
            
            return newString;
        },
        ValidDecimalNumbers: (value: string) => {
                let newString = '';
                let decimalCount = 0;
                for (let i = 0; i < value.length; i++) {
                    const char = value.substring(i, i + 1);
                    if (((char >= '0' && char <= '9') || (char == '.' && !newString.includes('.'))) && decimalCount < 2)   
                    {
                        if (newString.includes('.'))
                            decimalCount++;
                        newString += char;
                    }
                    
                }
                newString = newString == '.' ? '0.' : newString
                
                return newString;
        },
        ValidUSMoney: (value: number) => {
            return new Intl.NumberFormat('en-US',
                    { style: 'currency', currency: 'USD' }
                  ).format(value)
        }
            
        
    }

    static Validators = {
        IsValidEmail: (email: string) => {
            let emails = email.split(';').filter(x => x != '');
            // console.log('emails', emails);
            
            for (let email of emails)
            {
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim()))
                    return false;
                
            }
            return true;
            
        }
    }
}