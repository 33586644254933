import React from 'react';
import { NavLink } from 'react-router-dom';
import Helper from '../../../../Helper/Helper';

import './Home.scss';

const Home = () => {
    return (
        <>
       
    <nav className='navbar-component d-flex justify-content-between aling-items-center'>
        <ul className='d-flex'>
            <button className='btn' onClick={(evt) => Helper.Navigation.NavigateTo('/new-charge')}>
            <i className="fas fa-cash-register text-blue"></i>
             <div>
                 NEW CHARGE
             </div>
            </button>
            <button className='btn' onClick={(evt) => Helper.Navigation.NavigateTo('/charge-history')}>
            <i className="fas fa-chart-line text-yellow"></i>
            <div>
                CHARGE HISTORY</div>
            </button>
            <button className='btn' onClick={(evt) => Helper.Navigation.NavigateTo('/invoice-history')}>
            <i className="fas fa-file-invoice-dollar text-green"></i>
            <div>
                INVOICE HISTORY</div>
            </button>
            <button className='btn' onClick={(evt) => Helper.Navigation.NavigateTo('/daily-report')}>
            <i className="fas fa-chart-bar"></i>
            <div>
                DAILY REPORT</div>
            </button>
            
            <button className='btn' onClick={(evt) => Helper.Navigation.NavigateTo('/settings')}>
            <i className="fas fa-cog text-red"></i>
            <div>
                SETTINGS</div>
            </button>
        </ul>
        {/* <button className='btn btn-blue btn-large' onClick={(evt) => Helper.Session.DoLogout()}>LOG OUT</button> */}
    </nav>
    </>
    )
}

export default Home;