import React, { useEffect } from 'react';
import logo from './logo.svg';
// import './App.css';
import { Route, Switch, useHistory } from 'react-router-dom';
import Login from './components/login/Login';
import MainComponent from './components/main/MainComponent';
import Helper from './Helper/Helper';

import { GraphqlService } from './services/graphql.service';
import { GET_TERMINALS } from './graphql/queries/terminals';
import PayCharge from './components/PayCharge/PayCharge';
import AuthService from './services/auth.service';
import environments from './environment/environment';
import { encrypt } from './classes/encrypt';

function App() {

 
  const history = useHistory();

  Helper.SetNavigator(history);


  
  useEffect(()=> {
    
  }, []);



  return (
    <>
      <Switch>
        <Route path='/404' component={() => <div>404 NOT FOUND</div>} />
        <Route path='/invoice/:token' component={PayCharge} />
        <Route path='/login' component={Login} />
        <Route path='/' component={MainComponent} />
      </Switch>
    </>
  );
}

export default App;
