import React from 'react';

import './LoadSpinner.scss';
import load from '../../../assets/svg/Ajux_loader_full.gif';
const LoadSpinner = () => {
    return (<div className='load-spinner-component'>
        <div className='loading-spinner-container'>
            <img src={load} alt=""/>
            {/* <div className='d-flex justify-content-center aling-items-center'>
                <h6>Loading</h6><span>...<div /></span>
            </div> */}
        </div>
    </div>)
}

export default LoadSpinner;