import { gql } from "apollo-boost";

export const GET_ITEMS_NAME = gql`
    query {
        get_item_names{
            name
        }
    }
`

export const GET_CHARGES_PRESET = gql`
    query{
        get_charge_preset{
            id
            name
            base_amount
            tax_amount
            business{
                id
                name
            }
        }
    }
`

export const CREATE_CHARGE_PRESET = gql`
    mutation ($name: String!, $base_amount: Float!, $tax_amount: Float!) {
        create_charge_preset(name: $name, base_amount: $base_amount, tax_amount: $tax_amount) {
            id
            name
            base_amount
            tax_amount
        }
    }
`

export const UPDATE_CHARGE_PRESET = gql`
    mutation ($id: Int! $name: String!, $base_amount: Float!, $tax_amount: Float!) {
        update_charge_preset(name: $name, base_amount: $base_amount, tax_amount: $tax_amount id: $id)
    }
`

export const DELETE_CHARGE_PRESET = gql`
    mutation ($id: Int!) {
        delete_charge_preset(id: $id)
    }

`