import { gql } from "apollo-boost";

export const GET_ALL_BUSINESS_INFO = gql`
    query{
        business_settings{
            id
            receipt_text
                    invoice_text
        }
        business_profile{
            id
            phone
            email
            first_name
            last_name
        }

            business{
            id
            name
            active
            schema_id
            short_code
        }
    }
`

export const UPDATE_BUSINESS_INFO = gql`
    mutation ($name: String!){
        update_business(name:$name){
            success
            message
        }
    }

`


export const UPDATE_BUSINESS_PROFILE = gql`
   mutation ($email: String! $first_name: String! $last_name: String! $phone: String!){
        update_business_profile(email:$email first_name:$first_name last_name:$last_name phone:$phone){
            success
            message
        }
    }

`

export const UPDATE_BUSINESS_SETTINGS = gql`
   mutation ($receipt_text: String! $invoice_text: String!) {
        update_business_settings(receipt_text:$receipt_text invoice_text:$invoice_text){
            success
            message
        }
    }

`

export const UPDATE_PASSWORD = gql`
    mutation ($old_password: String! $new_password: String!) {
        change_password(username:"" old_password:$old_password new_password:$new_password){
            success
            message
        }
    }
`