import { Item } from "../classes/classes";
import EscPosEncoder from 'esc-pos-encoder';
import helper from '../Helper/Helper';

export class PrintReceipt {
    id: number = -1;
    last4: string = '';
    cardtype: string = '';
    subtype: string = '';
    name: string = '';
    items: PrinterItem[] = [];
    business: Business = new Business();
    order_reference: string = '';
    received_by: string = '';
    time: Date = new Date();

    constructor(x?: PrintReceipt)
    {
        if (x)
        {
            Object.assign(this, x);
        }
    }
}

export class ReportData {
    items: Item[] = [];
    order_reference: string = '';
    time: number = 0;

    constructor(x?: ReportData)
    {
        if (x)
        {
            Object.assign(this, x);
        }
    }
}

export class Business {
    name: string = '';
    phone: string = '';

    constructor (x?: Business)
    {
        if (x)
            Object.assign(this, x);
    }
}

export class PrintReport {
    business: Business = new Business();
    data: ReportData[] = [];
    date: string = '';

    constructor(x?: PrintReport)
    {
        if (x)
        {
            Object.assign(this, x);
        }
    }
}

let openRegister = new Uint8Array([27, 112, 0, 25, 250]);
let starPrinter: any = null;

export class  PrinterItem{
    name: string = '';
    base_amount: number = 0.00;
    tax_amount: number = 0.00;
    count: number = 1;
    amount: number;
    id: number;
    ticket_id: number;

    constructor(){
        this.name = '';
        this.base_amount = 0;
        this.tax_amount = 0;
        this.count = 1;
        this.amount = 0;
        this.id = -1;
        this.ticket_id = -1;
    }
}
export class  DailyItem{
    time: number;
    type: string;
    amount: string;
    status: string;
    payment_id: number;
    order_number: string;
    payment_number: string;
    transaction_number: string;

    constructor(){
        this.time = new Date().getTime();
        this.type = '';
        this.amount = '';
        this.status = '';
        this.payment_id = -1;
        this.order_number = '';
        this.payment_number = '';
        this.transaction_number = '';
    }
}
export class  Header{
    name: string;
    street: string;
    city_state: string;
    contact: string;

    constructor(){
        this.name = '';
        this.street = '';
        this.city_state = '';
        this.contact = '';
    }
}
export class  Footer{
    name: string;
    lastfour: string;
    subtype: string;
    transaction_id: string;
    type: string

    constructor(){
        this.name = '';
        this.lastfour = '';
        this.subtype = '';
        this.transaction_id = '';
        this.type = '';
    }
}


export class ReceiptData{
    items: PrinterItem[];
    header: Header;
    footer: Footer;
    order_reference: string;
    received_by: string;
    time: Date;
    order_received: string;
    constructor(x?: ReceiptData){
        this.items = [];
        this.header = new Header();
        this.footer = new Footer();
        this.order_reference = '';
        this.received_by = '';
        this.time = new Date();
        this.order_received = new Date().toLocaleDateString();

        if (x)
        {
            Object.assign(this, x);
        }
    }
}

export async function findStarPrinterDrawer() {
    try {
        const options = {
            filters: []
            //filters: []
        };
        let device = await (navigator as any).usb.requestDevice(options);
        console.log('Vendor ID:');
        console.log(device.vendorId);
        return device;
    } catch (ex) {
        console.log('ex 2', ex)
        return null;
    }
}

export async function printTheReport(items: DailyItem[], time: Date, header: Header) {
    try{
        if (!starPrinter){
            starPrinter = await findStarPrinterDrawer();
        }
        
        if (!starPrinter)
            throw new Error('Not connected');

        let device  = await starPrinter.open()
        .then(() => starPrinter.selectConfiguration(1))
        .then(() => {
            starPrinter.configuration.interfaces.forEach( (interf: any) =>{
                console.log(interf.interfaceNumber);
            });
            return starPrinter.claimInterface(starPrinter.configuration.interfaces[0].interfaceNumber);
        })
        .then(() => starPrinter);

        if(items){
            let encoder = new EscPosEncoder();
            let top = encoder
            .newline()
            .align('center')
            .line(header.name)
            .size('small')
            .line(' ')
            .size('normal')
            .line(header.street)
            .size('small')
            .line(' ')
            .size('normal')
            .line(header.contact)
            .size('small')
            .line(' ')
            .size('normal')
            .newline()
            .align('left')
            .line('_______________________________________________')
            .size('small')
            .line(' ')
            .size('normal')
            .line('PAYMENTS MADE ON')
            .line(time.toLocaleDateString())
            .line('_______________________________________________')
            .encode()

            let middle = encoder.newline().encode();
            items = items.sort((a, b) => (a.time > b.time) ? 1 : -1);
            items.forEach(item =>{
                console.log('\n\n\n\n\n');
                let itemTime: number = (item.time - 0) || (new Date().getTime());
                let value = new Date(itemTime);
                let text = value.toLocaleTimeString();
                console.log({itemTime, value, text});
                console.log('\n\n\n\n\n');

                middle = encoder
                .raw(middle)
                .newline()
                .align('left')
                .bold()
                .line(item.payment_number + ' FOR ' + item.order_number)
                .bold()
                .line(text)
                .align('right')
                .line(item.amount)
                .encode();
            });
            middle = encoder
                .raw(middle)
                .newline()
                .newline()
                .bold()
                .align('left')
                .line('TOTAL')
                .align('right')
                .line(helper.Masks.ValidUSMoney(items.map(a => parseFloat(a.amount.toUpperCase().replace('$','').replace('VOIDED', '0.00'))).reduce((a, b) => a + b)))
                .bold()
                .align('center')
                .line('_______________________________________________')
                .align('left')
                .newline()
                .encode();
            let body = encoder
                .raw(top)
                .raw(middle)
                .align('center')
                .line('**')
                .line('THANK YOU!')
                .line('**')
                .newline()
                .newline()
                .newline()
                .newline()
                .newline()
                .newline()
                .cut('partial')
                .encode()

            await device.transferOut(1, body);
        }
        return true;
    }
    catch (exc){
        console.log(exc.message);
        starPrinter = null;
        return false;
    }
}


export async function checkConnection(starPrinter: any) {
    try{
        if (!starPrinter){
            starPrinter = await findStarPrinterDrawer();
        }
        
        if (!starPrinter)
            throw new Error('Not connected');

        let device  = await starPrinter.open()
        .then(() => starPrinter.selectConfiguration(1))
        .then(() => {
            starPrinter.configuration.interfaces.forEach( (interf: any) =>{
                console.log(interf.interfaceNumber);
            });
            return starPrinter.claimInterface(starPrinter.configuration.interfaces[0].interfaceNumber);
        })
        .then(() => starPrinter);

        return true;
    }
    catch (exc){
        console.log(exc.message);
        starPrinter = null;
        return false;
    }
}

export async function openDrawer(starPrinter: any) {
    try{
        if (!starPrinter){
            starPrinter = await findStarPrinterDrawer();
        }
        
        if (!starPrinter)
            throw new Error('Not connected');

        let device  = await starPrinter.open()
        .then(() => starPrinter.selectConfiguration(1))
        .then(() => {
            starPrinter.configuration.interfaces.forEach( (interf: any) =>{
                console.log(interf.interfaceNumber);
            });
            return starPrinter.claimInterface(starPrinter.configuration.interfaces[0].interfaceNumber);
        })
        .then(() => starPrinter);

            

        await device.transferOut(1, openRegister);

        

        return true;
    }
    catch (exc){
        console.log(exc.message);
        starPrinter = null;
        return false;
    }
}

async function printTheReceipt(data: ReceiptData, openDrawer: boolean) {
    try{
        if (!starPrinter){
            starPrinter = await findStarPrinterDrawer();
        }
        
        if (!starPrinter)
            throw new Error('Not connected');

        let device  = await starPrinter.open()
        .then(() => starPrinter.selectConfiguration(1))
        .then(() => {
            // starPrinter.configuration.interfaces.forEach( (interf: any) =>{
            //     console.log(interf.interfaceNumber);
            // });
            return starPrinter.claimInterface(starPrinter.configuration.interfaces[0].interfaceNumber);
        })
        //.catch((ex1) => console.log(ex1))
        .then(() => starPrinter);

        if(!device){
            throw new Error('Failed to communicate with the printer');
        }

        let encoder = new EscPosEncoder();
        if (data && data.items)
        {
            let items = data.items.sort((a, b) => (a.amount < b.amount) ? 1 : -1) || [];
            let header = data.header;
            let footer = data.footer;

            console.log('\n\n\n\n');
            console.log(data.items);
            let tickets = data.items.length + ' item' + ((data.items.length > 1) ? 's' : '');
            
            let top = encoder
            .newline()
            .align('center')
            .line(header.name)
            .size('small')
            .line(' ')
            .size('normal')
            .line(header.street)
            .size('small')
            .line(' ')
            .size('normal')
            .line(header.contact)
            .size('small')
            .line(' ')
            .size('normal')
            .newline()
            .align('left')
            .line('_______________________________________________')
            .size('small')
            .line(' ')
            .size('normal')
            .line(data.time.toLocaleString())
            .line(tickets)
            .line('_______________________________________________')
            .newline()
            .align('left')
            .line('ORDER #: ' + data.order_reference)
            .align('right')
            .line(new Date(data.order_received).toLocaleDateString())
            .align('left')
            .line('RECEIVED BY: ' + data.received_by.toUpperCase())
            .align('right')
            .line(new Date(data.order_received).toLocaleTimeString())
            .line('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _')
            .encode()

            let middle = encoder.newline().encode();

            items.forEach(item =>{
                middle = encoder
                .raw(middle)
                .newline()
                .align('left')
                .line(item.count + ' X ' + item.name.toUpperCase())
                .align('right')
                .line(helper.Masks.ValidUSMoney(item.count * item.base_amount))
                .encode();
            });
            middle = encoder
                .raw(middle)
                .newline()
                .align('left')
                .line('TAX')
                .align('right')
                .line(helper.Masks.ValidUSMoney(items.map(a => a.tax_amount * a.count).reduce((a, b) => a + b)))
                .line('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _')
                .newline()
                .align('left')
                .line('TOTAL')
                .align('right')
                .line(helper.Masks.ValidUSMoney(items.map(a => a.amount).reduce((a, b) => a + b)))
                .align('center')
                .line('_______________________________________________')
                .align('left')
                .newline()
                .encode();

            let bottom = encoder
                .align('left')
                .line(footer.name.toUpperCase())
                .align('right')
                .line(footer.lastfour.toUpperCase())
                .align('left')
                .line('PAYMENT TYPE')
                .align('right')
                .line(footer.type.toUpperCase())
                .align('left')
                .line('CARD TYPE')
                .align('right')
                .line(footer.subtype)
                .align('left')
                .line('TRANSACTION ID')
                .align('right')
                .line(footer.transaction_id)
                .newline()
                .align('center')
                .line('SALE')
                .encode()

            let body = encoder
            .raw(top)
            .raw(middle)
            .raw(bottom)
            .newline()
            .newline()
            .newline()
            .newline()
            .newline()
            .newline()
            .cut('partial')
            .encode()
            
            await device.transferOut(1, body);
        }

        if(openDrawer){
            await device.transferOut(1, openRegister);
        }

        await device.close();

        return true;
    }
    catch (exc){
        console.log('This exc', exc.message);
        starPrinter = null;
        return false;
    }
}

export async function printReceipt(obj: PrintReceipt, openDrawer?: boolean)
{
    let header: Header = {
        name: obj.business.name,
        street: '',
        city_state: '',
        contact: obj.business.phone
    };
    let footer: Footer = {
        name: obj.name,
        lastfour: obj.cardtype + '**' + obj.last4,
        subtype: obj.subtype,
        transaction_id: 'T-' + obj.id,
        type: obj.cardtype
    };
    let items: PrinterItem[] = obj.items || [];
    let data: ReceiptData = {
        header,
        footer,
        order_reference: obj.order_reference,
        received_by: obj.received_by,
        items,
        time: obj.time,
        order_received: obj.time.toLocaleString()
    }

    await printTheReceipt(data, openDrawer || false);
}
export async function printReport(obj: PrintReport)
{
    let header: Header = {
        name: obj.business.name,
        street: '',
        city_state: '',
        contact: obj.business.phone
    };
    let items: DailyItem[] = [];

    obj.data.forEach(t =>{
        let data: DailyItem = {
            time: t.time,
            type: 'DAILY REPORT',
            amount: helper.Masks.ValidUSMoney(t.items.sumBy(x => x.amount)),
            status: 'STATUS',
            payment_id: 0,
            order_number: t.order_reference,
            payment_number: 'SALE',
            transaction_number: 'TRANSACTION' 
        };
        items.push(data);
    });

    await printTheReport(items, new Date(obj.date), header);
}

