import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ChargeHistory from './components/ChargeHistory/ChargeHistory';
import Home from './components/Home/Home';
import InvoiceHistory from './components/InvoiceHistory/InvoiceHistory';
import NewCharge from './components/NewCharge/NewCharge';
import NewInvoice from './components/NewInvoice/NewInvoice';
import { useLocation } from 'react-router-dom';


import './MainComponent.scss';
import Helper from '../../Helper/Helper';
import AuthService from '../../services/auth.service';
import Settings from './components/Settings/Settings';
import { GraphqlService } from '../../services/graphql.service';
import { CHECK_AUTH } from '../../graphql/queries/auth';
import DailyReport from './components/DailyReport/DailyReport';

const MainComponent = () => {

    const array = Array.from(Array(1000).keys())
    const location = useLocation();
    
    const check = async () => {
        try {
            await GraphqlService.SendQuery(CHECK_AUTH);
        } catch (ex) {
            
        }
    }

    useEffect(() => {
        if (AuthService.TOKEN.trim() != '')
            check();
    });

    if (AuthService.TOKEN.trim() == '')
    {
      return (<Redirect to='/login' />)
    }
    // console.log(array.length)
    return (
        <>
     <div className='p-2 d-flex align-items-center justify-content-between' style={{position: 'sticky', zIndex: 2, background: '#FFF', boxShadow: '3px 4px 12px #00000029', top: 0}}>

<div className='d-flex align-items-center' style={{cursor: 'pointer', userSelect: 'none'}} onClick={(evt) => Helper.Navigation.NavigateTo('/')}>
    <img style={{width: '2.8rem'}} src={Helper.Images.ProfuseLogoBlack} alt=""/>
    <h4 className='ml-2' style={{fontSize: '1.5rem', fontFamily: "'Barlow', sans-serif", fontWeight: 300, position: 'sticky', top: '0', background: '#FFF'}}>PROFUSE PAY</h4>
</div>
<button onClick={(evt) => Helper.Session.DoLogout()} className='btn' style={{borderRadius: '50%', background: '#FFF'}}><i className="fas fa-power-off"></i></button>
</div>
            <div className='main-container'>

             
            
                <div className='main-content'>
                {location.pathname != '/' && <div style={{display: 'inline-block', cursor: 'pointer', userSelect: 'none', padding: '.8rem 0 0 1rem'}} onClick={(evt) => Helper.Navigation.NavigateTo('/')}>
                            <i className="fas fa-arrow-left mr-2"></i> GO BACK
                        </div>}
                    <div className={'inner-content' + (location.pathname == '/' ? ' home' : '')}>
                        
                      
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/new-charge' component={NewCharge} />
                            <Route path='/charge-history' component={ChargeHistory} />
                            <Route path='/new-invoice' component={NewInvoice} />
                            <Route path='/invoice-history' component={InvoiceHistory} />
                            <Route path='/settings' component={Settings} />
                            <Route path='/daily-report' component={DailyReport} />
                            <Redirect to='/404'></Redirect>
                        </Switch>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainComponent;