import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Link } from "react-router-dom";



import moment from 'moment';
import environments from './environment/environment';
import AuthService from './services/auth.service';

import crypto from 'crypto-js';

import '@szhsin/react-menu/dist/index.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { GraphqlService } from './services/graphql.service';
import client from './config/graphql.client';

const getPlatform = (token: string) => {
  let secret = token.substring(token.length - 8);
  let time = new Date().getTime().toString();
  return crypto.AES.encrypt(time, secret).toString();
  
}



AuthService.TOKEN = localStorage.getItem('token') || '';
environments.IFRAME_LINK = localStorage.getItem('iframe_link') || environments.IFRAME_LINK;

// EXTENSION

declare global {
  interface Number {
    toCurrency(currency?: string,useCommaSeparator?: boolean, useDecimalSeparator?: boolean): string;
  }
   
  interface Array<T> {
    sumBy(predicate: (value: T) => number): number;
  }

  interface Date {
    addDays(days: number): Date
  }

  interface JSON {
    clone<T>(obj: T): T;
    compare<T>(obj1: T, obj2: T): boolean
  }
}


const initArrays = () => {

  JSON.clone = (data) => {
    return JSON.parse(JSON.stringify(data));
  }

  JSON.compare = (obj1, obj2) => {
    return JSON.stringify(obj1) == JSON.stringify(obj2);
  }

  

  

  Array.prototype.sumBy = function(params) {
    let sum = 0;
    let array = this.map(params) || [];
    array.forEach(x => {
      if (!isNaN(x))
        sum += Number(x);
    });
    return sum;
  }

  Date.prototype.addDays = function(days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }

    Number.prototype.toCurrency = function(currency: string = '$', useCommaSeparator: boolean = true, useDecimalSeparator: boolean = true){
            
      if(currency == '$' && useCommaSeparator && useDecimalSeparator){
          let value = isNaN(this) ||this as any == '-0' ? 0 : this;
  
          return new Intl.NumberFormat('en-US',
                  { style: 'currency', currency: 'USD' }
              ).format(value)
      }
  
      if(this < 0){
          currency = '-' + currency;
      }
      
  
      let txt = '', res = '';
      let temp: string = this.toString();
      let foundDecimalPoint = false;
      for(let i = 0; i< temp.length; i++){
          if('0123456789'.includes(temp[i]) || (temp[i] == '.' && !foundDecimalPoint)){
              if(temp[i] == '.') foundDecimalPoint = true;
              txt += temp[i];
          }
      }
      txt = (+txt).toFixed(2).toString();
      if(useDecimalSeparator){
          txt = txt.replace('.','');
          if(!txt){
              return currency + '0.00';
          }
          else if(txt.length == 1){
              return currency + '0.0' + txt;
          }
          else if(txt.length == 2){
              return currency + '0.' + txt;
          }
          else{
              res = '.' + txt.substring(txt.length - 2);
              txt = txt.substring(0, txt.length - 2);
          }
      }
      if(useCommaSeparator){
          if(!txt){
              return currency + '0' + res;
          }
          
          while (txt.length > 3){
              res = ',' + txt.substring(txt.length - 3) + res;
              txt = txt.substring(0, txt.length - 3);
          }
      }
      return currency + txt + res;

  }
}


initArrays();



GraphqlService.SetClient(client);
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
          <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
