import { gql } from 'apollo-boost';
import React, { useEffect, useRef, useState } from 'react';
import { useApolloClient, useQuery } from 'react-apollo';
import { Item, PresetItem, Terminal, UIItem, UIPresetItem } from '../../../../classes/classes';
import { PROCESS_CHARGE } from '../../../../graphql/mutations/charge';
import { GET_TERMINALS } from '../../../../graphql/queries/terminals';
import { GraphqlService } from '../../../../services/graphql.service';
import Combobox from '../../../shared/Combobox/Combobox';
import LoadSpinner from '../../../shared/LoadSpinner/LoadSpinner';
import CardInput from '../../../shared/CardInput/CardInput';

import './NewCharge.scss';
import { SEND_INVOICE } from '../../../../graphql/queries/invoice';
import { GET_CHARGES_PRESET, GET_ITEMS_NAME } from '../../../../graphql/queries/item';
import MessageBox from '../../../shared/MessageBox/MessageBox';
import IPayInfo from '../../../shared/CardInput/models/IPayInfo';
import { SEND_MANUAL_TRANACTION } from '../../../../graphql/mutations/transactions';
import Helper from '../../../../Helper/Helper';
import { GET_ALL_BUSINESS_INFO } from '../../../../graphql/queries/business';
import Swal from 'sweetalert2';
import { Business, printReceipt, PrintReceipt } from '../../../../services/printer.service';

const NewCharge = () => {



    const [items, setItems] = useState<UIItem[]>([]);

    const [isValid, setIsValid] = useState(false);
    
    const [business, setBusiness] = useState<{name: string, phone: string}>({name: '', phone: ''});
    const [inputManuallyShown, setInputManuallyShown] = useState(false);
    const [sendAsInvoiceShown, setSendAsInvoiceShown] = useState(false);
    const [focus, setFocus] = useState(-1);
    const [terminalsShown, setTerminalsShown] = useState(false);
    const [terminals, setTerminals] = useState<Terminal[]>([])
    const [loading, setLoading] = useState(false);
    const [orderReference, setOrderReference] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [note, setNote] = useState('');
    const [itemsLoaded, setItemsLoaded] = useState(false);
    const [hsn, setHsn] = useState('');
    
    const [presetItems, setPresetItems] = useState<PresetItem[]>([]);

    const [quickSOpen, setQuickSOpen] = useState(false);

    const [quickAmount, setQuickAmount] = useState<number>('' as any)
    
    const [searchTerm, setSearchTerm] = useState('');

    const [presetModal, setPresetModal] = useState<{idx: number, open: boolean}>({idx: -1, open: false})

    const quickAmountInpRef = useRef<any>();

    const inpRef = useRef<any>();


   
    
    const closeAll = () => {
        let temp = [...items];
        temp.map(x => x.expanded = false);
        setItems(temp);
    }

    const loadBusinessInfo = async () => {
        try {
            let data: any = await GraphqlService.SendMultipleQuery(GET_ALL_BUSINESS_INFO) || [];
            setBusiness({
                name: data[2]?.name,
                phone: data[1]?.phone
            })
        } catch (ex) {
            
        }
    }

    const loadItemsName = async () => {
        try {
            let data = await GraphqlService.SendQuery(GET_CHARGES_PRESET);

            setPresetItems(data);
            
        } catch (ex) {
            setOpen(true);
            setMessage(ex.message);
            setTitle('Error');
            // console.log(ex.message);
        }
    }

    const loadTerminals = async () => {
        try {
            setLoading(true);
            let data = await GraphqlService.SendQuery(GET_TERMINALS) || [];
            
            setLoading(false);
            closeAll();
            // console.log('terminals', data)
            setTerminals(data)
            setHsn('');
            setTerminalsShown(true);
            if (data.length == 1){
                setHsn(data[0].hsn)
                handleSwipeCard(null, data[0].hsn);
            }
            
        } catch (ex) {
            setLoading(false);
            
            setOpen(true);
            setMessage(ex.message);
            setTitle('Error');
        }
    }

    

    


    useEffect(() => {
        // load();
        // console.log('items loaded', itemsLoaded);
        if (!itemsLoaded)
        {
            setItemsLoaded(true);
            loadItemsName();
            loadBusinessInfo();
        }
        setIsValid((items.filter(x => x.valid).length == items.length))
        setInvalidEmail(email.length == 0? true : !Helper.Validators.IsValidEmail(email))
    }, [items, orderReference, email])


   

    const handleToggleExpand = (index: number) => {
        if (terminalsShown || inputManuallyShown || sendAsInvoiceShown)
            return;
        let temp = [...items];
        temp.map((y, i) => i != index ? y.expanded = false : y.expanded);
        temp[index].expanded = !temp[index].expanded;
        setItems(temp);
    }


    const handleNameChanged = (value: string, index: number) => {
        // console.log(value);
        let temp = [...items];
        temp[index].name = value;
        
        checkValidity(temp, index);
    }

    const handleBaseAmountChanged = (value: string, index: number) => {
        // console.log('valie', value);
        let temp = [...items];

        // console.log('value', value, getOnlyNumbers(value) );
        // console.log('valie', getOnlyNumbers(value));
        temp[index].base_amount = getOnlyNumbers(value) as any;

        
        // console.log(temp[index].base_amount);
        // console.log(temp[index]);
        
        onTaxChanged(temp[index].tax, index, temp);
    }

    const handleCountChanged = (value: string, index: number) => {

        let temp = [...items];
        temp[index].count = getOnlyNumbers(value) as any;
        onTaxChanged(temp[index].tax, index, temp);
    }

    const handleTaxAmountChanged = (value: string, index: number) => {
        // console.log(value);
        let temp = [...items];
        temp[index].tax_amount = getOnlyNumbers(value) as any;
        onTaxChanged(temp[index].tax, index, temp, true);
    }

    const getOnlyNumbers = (value: string) => {
        let newString = '';
        let decimalCount = 0;
        for (let i = 0; i < value.length; i++) {
            const char = value.substring(i, i + 1);
            if (((char >= '0' && char <= '9') || (char == '.' && !newString.includes('.'))) && decimalCount < 2)   
            {
                if (newString.includes('.'))
                    decimalCount++;
                newString += char;
            }
            
        }
        return newString == '.' ? '0.' : newString;
    }

    const calculateTotal  = (temp: UIItem[], index: number) => {

        let base = temp[index].base_amount;
        let tax = temp[index].tax_amount;

        const total = Number(base) + Number(tax);
        
        temp[index].amount = Number(( Number(total.toFixed(2)) * Number(Number(temp[index].count))).toFixed(2));


        checkValidity(temp, index);
    }

    const checkValidity = (temp: UIItem[], index: number) => {

        let valid = temp[index].name.trim() != '' && Number(temp[index].base_amount) != 0 && Number(temp[index].count) != 0;
        temp[index].valid = valid;
        setItems(temp);
    }


    const removeItem = (index: number) => {
        let temp = [...items];

        temp.splice(index, 1);

        setItems(temp);
    }


    

    

    const handlePrintReceipt = (obj: any) => {
        Swal.fire({
            icon: 'question',
            title: 'Print Receipt',
            text: 'Please select an option',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'REGULAR PRINT',
            denyButtonText: 'POS PRINT',
            cancelButtonText: 'Cancel'
        })
        .then((d) => {
            if (d.isConfirmed)
            {
                const MAX_LENGTH = 20;
        const aligned = (left: string, right: string) => {
            return `<span>${left}</span><span>${right}</span>`;
        }


        const _items = obj.items.map((x: any, i: number, []) => {
            let p = `<p style='font-weight: bold;'>${x.count}x ${x.name}</p>`
            let pamount = `<p style='display: flex; justify-content: space-between;'>${aligned('Amount', '$' + Number(x.base_amount).toFixed(2))}</p>`
            let ptax = `<p style='display: flex; justify-content: space-between;'>${aligned('Tax', '$' + Number(x.tax_amount).toFixed(2))}</p>`
            let psubtotal= `<p style='display: flex; justify-content: space-between;'>${aligned('Subtotal', '$' + Number(x.amount).toFixed(2))}</p>`

            let div = `
                <div style='margin-bottom: 6px;'>
                    ${p}
                    ${pamount}
                    ${ptax}
                    ${psubtotal}
                    <p style='text-align: center;'>-------------------------------</p>
                <div>
            `
            return div;
        });
        const body = `
        <h2 style='text-align: center;'>${business.name}</h2>
        <h3 style='text-align: center; margin-bottom: 12px;'>${business.phone}</h3>
        <p style='text-align: center;'>***</p>
        <p style='margin-bottom: 6px'>Transaction: ${obj.id}</p>
            <p style='font-weight: bold; margin-bottom: 12px;'>Items</p>
            ${_items.join('')}
            <p style='display: flex; justify-content: space-between; margin-bottom: 0;'>${aligned('Total', '$' + obj.items.sumBy((x: any) => x.amount).toFixed(2))}</p>
            <p style='margin:8px 0; text-align: center;'>-----------------------------------</p>
            <p style='margin-bottom: 8px;'>${obj.cardtype} **${obj.last4}</p>
            <p style='margin-bottom: 8px;'>${obj.name}</p>
            <p style='margin-bottom: 8px;'>${obj.subtype}</p>
            
            <p style='text-align: center;'>***</p>
            <p style='text-align: center;'>***</p>

            <p style='text-align: center;'>THANK YOU!</p>
            <p style='text-align: center;'>***</p>
        `

        let d = `<style> html,body { margin: 0; padding: 0; } p, h2, h3 { margin: 0 16px 0 4px; } .mb { margin-bottom: 6px; } .mb-2 { margin-bottom: 12px; } </style><div style='padding: 0 8px 8px 8px;'>${body}</div>`
            var mywindow = window.open('PRINT', '_blank');
            

            // mywindow?.document.appendChild(style)
            
        mywindow?.document.write(d);
        mywindow?.print();
            }
            else if (d.isDenied)
            {
                let b = new Business({name: business.name, phone: business.phone});
                let pr = new PrintReceipt({
                    business: b,
                    cardtype: obj.cardtype,
                    items: obj.items,
                    id: obj.id,
                    last4: obj.last4,
                    name: obj.name,
                    subtype: obj.subtype,
                    time: obj.time || new Date(),
                    order_reference: obj.order_reference || '',
                    received_by: obj.received_by || ''
                });
                printReceipt(pr);
            }
        })
        
    }

    const handleSwipeCard = async (evt: any, _hsn?: string) => {
        setLoading(true);
        try {

            let body = {order_reference: orderReference, hsn: _hsn || hsn, items: items.map(x => ({name: x.name, count: +x.count, base_amount: +x.base_amount, tax_amount: +x.tax_amount, amount: +x.amount}))};
            // console.log(body)
            let data = await GraphqlService.SendMutation(PROCESS_CHARGE, body) || {};

            // console.log(data);

           
            setLoading(false)
            if (data.success)
            {
                cleanAll();
                let cardtype = '';
                // console.log(data);
                switch(data.data.binInfo?.product){
                    case 'A': cardtype = 'Amex'; break;
                    case 'D': cardtype = 'Discover'; break;
                    case 'M': cardtype = 'Mastercard'; break;
                    case 'V': cardtype = 'Visa'; break;
                    default: cardtype = 'Non-co-branded card'; break;
                  }
                handlePrintReceipt({
                    id: data.data.id,
                    last4: data.data.token.substring(data.data.token.length - 4, data.data.token.length),
                    cardtype,
                    subtype: data.data.binInfo?.subtype || '',
                    name: data.data.name,
                    items: body.items
                });
            }
            else
            {

                setOpen(true);
                setMessage(data.message || 'Payment Declined');
                setTitle('Error');
            }
        } catch (ex) {
            
            setLoading(false)
            setOpen(true);
            setMessage(ex.message);
            setTitle('Error');
        }
    }

    const addItem = () => {
        let temp = [...items];

        temp.map(x => x.expanded = false);
        temp.unshift(new UIItem())

        setItems(temp);
    }

    const taxes = [{name: 'No Tax\t\t0.00%', amount: 0}, {name: 'Sales Tax\t\t9.5%', amount: 9.5}];

    const onTaxChanged = (value: number, index: number, tempItems? :UIItem[], skip?: boolean) => {
        const temp =  tempItems || [...items];
        if (!skip)
        {

            temp[index].tax = value;
            temp[index].tax_amount = Number(((Number(temp[index].base_amount) * (!isNaN(value) ? value : 0)) / 100).toFixed(2));
        }
        // console.log(temp[index]);
        calculateTotal(temp, index);
    }
    



    const handlePhoneChanged = (value: string) => {
        setPhone(Helper.Masks.ValidPhone(value));
    }

    

    const handleSendAsInvoice = async  (evt: any) => {
        try {
            let obj = {
                customer_name: customerName,
                phone,
                email,
                order_reference: orderReference,
                items: items.map((x) => ({name: x.name, base_amount: +x.base_amount, tax_amount: +x.tax_amount, count: +x.count, amount: +x.amount})),
                note: note
            }
            setLoading(true);
            // console.log(obj);
            let data = await GraphqlService.SendMutation(SEND_INVOICE, obj);

            closeAll();
            setLoading(false);
            // console.log(data);

            cleanAll();
        } catch (ex) {
            
            setLoading(false);
            
            setOpen(true);
            setMessage(ex.message);
            setTitle('Error');
        }
    }


    const sendManualPayment = async (payInfo: IPayInfo) => {
        setLoading(true);
        let temp = JSON.parse(JSON.stringify(payInfo));
        delete temp.email;
        try {
            let body = {order_reference: orderReference, payInfo: temp, items: items.map(x => ({name: x.name, count: +x.count, base_amount: +x.base_amount, tax_amount: +x.tax_amount, amount: +x.amount}))};
            // console.log(JSON.stringify(body))
            let data = await GraphqlService.SendMutation(SEND_MANUAL_TRANACTION, body)
            // console.log(data);
            setLoading(false)
            if (data.success)
            {
                cleanAll();
                let cardtype = '';
                switch(data.data.binInfo?.product) {
                    case 'A': cardtype = 'Amex'; break;
                    case 'D': cardtype = 'Discover'; break;
                    case 'M': cardtype = 'Mastercard'; break;
                    case 'V': cardtype = 'Visa'; break;
                    default: cardtype = 'Non-co-branded card'; break;
                }
                handlePrintReceipt({
                    id: data.data.id,
                    last4: data.data.token.substring(data.data.token.length - 4, data.data.token.length),
                    cardtype,
                    subtype: data.data.binInfo?.subtype || '',
                    name: data.data.name,
                    items: body.items
                });
            }
            else
            {

                setOpen(true);
                setMessage(data.message || 'Payment Declined');
                setTitle('Error');
            }
        } catch (ex) {
            
            console.log(ex.networkError.result.errors);
            setLoading(false)
            setOpen(true);
            setMessage(ex.message);
            setTitle('Error');
        }
    }

    const cleanAll = () => {
        setItems([]);
        setOrderReference('');
        setEmail('');
        setPhone('');
        setCustomerName('');
        setInputManuallyShown(false);
        setTerminalsShown(false);
        setSendAsInvoiceShown(false);
        setTerminals([]);
        setNote('');
        setLoading(false);
        loadItemsName();
    }

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const handleQuickSale = () => {
        if (Number(quickAmount) <= 0) return
        let temp = [...items];


        temp.map(x => x.expanded = false);
        const item = new UIItem();

        item.amount = Number(quickAmount);
        item.base_amount = Number(quickAmount);
        item.tax_amount = 0;
        item.valid = true;
        item.tax = 0;
        item.expanded = false;
        item.name = 'SALE';

        temp.unshift(item);

        setItems(temp);
        
        setQuickAmount('' as any);
        setQuickSOpen(false);
    }

    const handlePresetItemClicked = (item: PresetItem) => {
        setSearchTerm('');
        // console.log(item);
        const idx = presetModal.idx;
        handleNameChanged(item.name, idx);
        handleBaseAmountChanged(item.base_amount.toString(), idx);
        handleTaxAmountChanged(item.tax_amount.toString(), idx);
        if (item.tax_amount > 0)
        {
            const temp = [...items];
            temp[idx].tax = 9.5;
            setItems(temp);
        }
        
        setPresetModal({idx: -1, open: false})

    }

    return (
        <div className='new-charge-component'>
            <MessageBox isOpen={open} setIsOpen={setOpen} message={message} title={title} />
           {loading  && <LoadSpinner />}
           {presetModal.open && <div className="modal">
               <div className="modal-content">
                   <div className='col-12'>
                        <h4 className="form-title">PRESET ITEMS {presetItems.length} / { presetItems.filter(item => JSON.stringify(item).toLocaleLowerCase().includes(searchTerm.trim().toLowerCase())).length}</h4>
                    <div style={{position: 'relative'}} className='col-12 mb-3'>
                        <img onClick={(evt) => inpRef.current.focus()} style={{position:'absolute', top: '50%', left: '.15rem', transform: 'translateY(-50%)', cursor: 'pointer'}} src={Helper.Icons.IconSearch} alt=""/>
                        <input className='search-input col-12' ref={inpRef} value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)} type="text" placeholder='Search....' style={{width: '100%'}} />
                        <img onClick={(evt) => {setSearchTerm('')}} style={{position:'absolute', top: '50%', right: '0.45rem', transform: 'translateY(-50%)', cursor: 'pointer'}} src={Helper.Icons.IconDelete} alt=""/>
                    </div>
                   </div>

                <div className='col-12' style={{maxHeight: 'calc(100vh - 14rem)', minHeight: 'calc(100vh - 14rem)', overflow: 'auto'}}>

                    {presetItems.map(item => JSON.stringify(item).toLocaleLowerCase().includes(searchTerm.trim().toLowerCase()) &&
                            <button className='btn item-header col-12 d-flex justify-content-between mb-2 valid blue' onClick={(evt) => {handlePresetItemClicked(item)}}>
                                <h4>{item.name}</h4>
                                <h4>${Number(item.base_amount + item.tax_amount).toFixed(2)}</h4>
                            </button>
                        )}
                </div>
                <button className='mt-2 btn' onClick={(evt) => {setSearchTerm(''); setPresetModal({idx: -1, open: false})}}>
                    CANCEL
                </button>
               </div>
           </div>}
           {quickSOpen && <div className='modal'>
                <div className='modal-content' style={{top: '30%'}}>
                    <h4 className="form-title">QUICK SALE</h4>
                    <div className='form-group'>
                        <label htmlFor="qam" >Amount*</label>
                        <input id='qam' name='qam' ref={(evt => evt?.focus())} value={quickAmount} onKeyPress={(evt) => {if (evt.which == 13) handleQuickSale()}} onChange={(evt) => setQuickAmount(getOnlyNumbers(evt.target.value) as any)} type="text" className="form-field " style={{fontSize: '2.4rem'}}/>
                    </div>
                    <div className='d-flex justify-content-between mt-3'>
                        <button className="btn" onClick={(evt) => {setQuickSOpen(false); setQuickAmount('' as any)}}>CANCEL</button>
                        <button className="btn btn-orange" disabled={Number(quickAmount) <= 0} onClick={(evt) => handleQuickSale()}>SUBMIT</button>
                    </div>
                </div>
           </div>}
            <form action="" className='d-flex flex-wrap justify-content-between small-form'>
                
                    <div className=' sticky-header pt-2 pb-4 col-12'>
                        <button  type='button' style={{fontSize: '2rem'}} className="btn btn-blue col-12 py-5" onClick={(evt) => {
                            setQuickSOpen(true);
                        }}>QUICK SALE</button>
                    </div>
                <div className='col-12'>
                    <div className='col-12 mb-4'>
                        <label className='form-title' htmlFor="">ORDER REFERENCE</label>
                        <input disabled={inputManuallyShown || terminalsShown || sendAsInvoiceShown} placeholder='Type order reference here...' value={orderReference} onChange={(evt) => setOrderReference(inputManuallyShown || terminalsShown || sendAsInvoiceShown ? orderReference : evt.target.value)} type="text" className="form-field"/>
                    </div>
                    <div className='d-flex justify-content-between col-12 align-items-center mb-3'>
                        <button disabled={!isValid || terminalsShown || inputManuallyShown || sendAsInvoiceShown} type='button' className='btn btn-orange' onClick={(evt) => addItem()}>ADD NEW CHARGE</button>
                    </div>
                </div>
                

                <div className='col-12'>
                    <div>

                        {items.map((item, i) => 
                            <div id={'i-'+ i} key={`i-${i}`} className='mb-1'> 
                                 <button type='button' className={'btn item-header d-flex justify-content-between col-12 '  + (item.expanded ? 'expanded' : '')  + (item.valid ? ' valid' : '')}  onClick={(evt) => handleToggleExpand(i)}>
                                <h4>{item.name}</h4>
                                <h4>${item.amount.toFixed(2)}<span className='ml-2'>{item.expanded ? '-' : '+'}</span></h4>
                        </button>
                        <div className={"item-content col-12 d-flex justify-content-between flex-wrap px-3 pt-3 " + (item.expanded ? 'expanded' : '')}>
                            <div className='d-flex col-12'>
                                <div className='form-group col-12 mb-3 position-relative'>
                                    <label htmlFor="" >Name*</label>
                                    <input onFocus={(evt) => setFocus(i)} onBlur={(evt) => setTimeout(() => setFocus(-1), 100)} value={item.name} onChange={(evt) => handleNameChanged(evt.target.value, i)} className='form-field' type="text"/>
                                    {/* <img src={Helper.Icons.IconSearch} alt=""/> */}
                                    {/* <Combobox searchTerm={item.name} data={presetItems} show={focus == i} itemSelected={(data: UIPresetItem) => {handleNameChanged(data.name, i); onTaxChanged(data.tax, i); handleBaseAmountChanged(data.base_amount.toString(), i); handleTaxAmountChanged(data.tax_amount.toString() || '0', i)}} /> */}
                                </div>
                                <div className='ml-2 d-flex align-items-center justify-content-end'>
                                    <button type='button' className="btn btn-orange" onClick={(evt) => setPresetModal({idx: i, open: true})}>PRESETS</button>
                                </div>
                            </div>
                            <div className='form-group col-6 mb-3'>
                                    <label htmlFor="">Base Amount*</label>
                                    <input value={item.base_amount} onChange={(evt) => handleBaseAmountChanged(evt.target.value, i)} className='form-field' type="text"/>

                                </div>
                                <div className='form-group col-6 mb-3'>
                                    <label htmlFor="">Count*</label>
                                    <input value={item.count} onChange={(evt) => handleCountChanged(evt.target.value, i)} className='form-field' type="text"/>

                                </div>
                            <div className='form-group col-6'>
                                <label htmlFor="">Tax Amount*</label>
                                <input value={item.tax_amount} onChange={(evt) => handleTaxAmountChanged(evt.target.value, i)} className='form-field' type="text"/>
                                {/* <select className='form-field' name="" id="">
                                    <option value="0">0%</option>
                                    <option value="9.5">9.5%</option>
                                </select> */}

                            </div>
                            <div className='form-group col-6'>
                                <label htmlFor="">Tax*</label>
                                {/* <input value={item.tax_amount} onChange={(evt) => handleTaxAmountChanged(evt.target.value, i)} className='form-field' type="text"/> */}
                                <select disabled={item.base_amount == 0} className='form-field' value={item.tax} onChange={(evt) => onTaxChanged(Number(evt.target.value), i)}>
                                    {taxes.map((item, i) => <option key={'opt-' + i} value={item.amount}>{item.name}</option>)}
                                </select>

                            </div>
                            
                            <div className='form-group col-6 mt-3'>
                                <label htmlFor="">Total Amount*</label>
                                <input value={item.amount} className='form-field' type="text" readOnly/>

                            </div>

                            <div className='col-6 d-flex justify-content-end align-items-end'>

                                    <button type='button' className='btn btn-danger' onClick={(evt) => removeItem(i)}>REMOVE</button>
                            </div>
                        </div>
                    
                        </div>
                        )}
                    </div>
                    
                    <div className='d-flex justify-content-between col-12 mt-3'>
                        <h4 className='m-0' style={{fontSize: '2rem'}}>TOTAL:</h4>
                        <h4 className='m-0' style={{fontSize: '2rem'}}>${items.sumBy(x => x.amount).toFixed(2)}</h4>
                    </div>
                   {(!terminalsShown && !inputManuallyShown && !sendAsInvoiceShown) &&  <div className='d-flex justify-content-center align-items-center mt-3 flex-wrap'>
                                <button disabled={!isValid || items.length == 0} type='button' className='btn btn-blue col-12 mb-3 py-4' onClick={(evt) => loadTerminals()}>SWIPE CARD</button>
                                {/* <p className='black-text mx-2'>OR</p> */}
                                <button disabled={!isValid || items.length == 0} type='button' className='btn btn-orange col-12 mb-3' onClick={(evt) => {closeAll(); setInputManuallyShown(true)}}>INPUT MANUALLY</button>
                                <button disabled={!isValid || items.length == 0} type='button' className='btn col-12 mb-3' onClick={(evt) => {setSendAsInvoiceShown(true); closeAll()}}>SEND AS INVOICE</button>
                                
                            </div>}
                    {inputManuallyShown && <CardInput onPaymentSubmitted={(payInfo) => sendManualPayment(payInfo)} onCancelClicked={(evt) => setInputManuallyShown(false)} />}
                   {terminalsShown && <div className='col-12'>
                        <div className='form-group mb-3'>
                            <label htmlFor="">Terminal</label>
                            <select name="" id="" className='form-field' value={hsn} onChange={(evt) => setHsn(evt.target.value)}>
                                <option value="">-- SELECT --</option>
                                {terminals.map((item, i) => <option key={'topt-' + i} value={item.hsn}>{item.name}</option>)}
                            </select>
                        </div>
                        <div className='d-flex justify-content-between'>

                            <button disabled={!isValid} type='button' className='btn' onClick={(evt) => setTerminalsShown(false)}>Cancel</button>
                            <button disabled={!isValid || !hsn} type='button' className='btn btn-orange' onClick={(handleSwipeCard)}>SWIPE CARD</button>
                        </div>
                    </div>}

                    {sendAsInvoiceShown &&
                        <div className='col-12'>
                            <div className='form-group mb-3'>
                                <label htmlFor="cust_name">CUSTOMER NAME (Optional)</label>
                                <input value={customerName} onChange={(evt) => setCustomerName(evt.target.value)} id='cust_name' name='cust_name' className='form-field' type="text"/>
                            </div>
                            <div className='form-group mb-3'>
                                <label htmlFor="email">EMAIL*</label>
                                <input value={email} onChange={(evt) => setEmail(evt.target.value)} id='email' name='email' className='form-field' type="email"/>
                            </div>
                            <div className='form-group mb-3'>
                                <label htmlFor="phone">PHONE NUMBER*</label>
                                <input value={phone} onChange={(evt) => handlePhoneChanged(evt.target.value)} id='phone' name='phone' className='form-field' type="tel"/>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="note">NOTE</label>
                                <textarea value={note} onChange={(evt) => setNote(evt.target.value)} id='note' name='note' className='form-field' />
                            </div>
                            <div className='d-flex justify-content-between mt-3'>

                                <button type='button' className='btn' onClick={(evt) => {setSendAsInvoiceShown(false); setEmail(''); setCustomerName(''); setPhone(''); setNote('')} }>Cancel</button>
                                <button disabled={!isValid ||   (email.length == 0 ? phone.length < 12 : phone.length == 0 ? invalidEmail : (invalidEmail || phone.length < 12)) } type='button' className='btn btn-orange' onClick={(handleSendAsInvoice)}>SEND AS INVOICE</button>
                            </div>
                        </div>}
                </div>
            </form>        
        </div>
    )
}

export default NewCharge;