import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DELETE_INVOICE } from '../../../../graphql/mutations/invoice';
import { GET_ALL_INVOICES, RESEND_INVOICE } from '../../../../graphql/queries/invoice';
import { GET_ALL_TRANSACTIONS, RESEND_RECEIPT, VOID_TRANSACTION } from '../../../../graphql/queries/transactions';
import Helper from '../../../../Helper/Helper';
import IMenuOption from '../../../../models/IMenuOption';
import { GraphqlService } from '../../../../services/graphql.service';
import LoadSpinner from '../../../shared/LoadSpinner/LoadSpinner';
import MessageBox from '../../../shared/MessageBox/MessageBox';
import IColumn from '../../../shared/Table/models/IColumn';
import IRow from '../../../shared/Table/models/IRow';
import Table from '../../../shared/Table/Table';

const InvoiceHistory = () => {
    const [rows, setRows] = useState<IRow[]>(null as any);


    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState<string[]>([]);
    
    async function load(evt?: {complete: () => void, startDate: string, endDate: string}) 
    {
        if (evt)
        {
            setDateRange([evt.startDate, evt.endDate]);
        }
        try {
            let data = await GraphqlService.SendQuery(GET_ALL_INVOICES, {start_date: new Date(evt?.startDate || dateRange[0]), end_date: new Date(evt?.endDate || dateRange[1]).addDays(1)}) as any[];
            
            
            let temp: IRow[] = data.map(x => {
                let result: IRow = [
                [
                    {label: 'INV-' + x.id, type: 'text', value: x.id}
                ],
                [
                    {label: x.order_reference, type: 'text', value: x.order_reference}
                ],
                [
                    {label: x.cardconnect_transaction != null ? 'PAID' : 'NOT PAID', type: 'text', value: x.cardconnect_transaction != null ? 'PAID' : 'NOT PAID'}
                ],
                x.cardconnect_transaction == null ? [ {label: '', type: 'text', value: ''} ] : 
                [
                    {
                        label: 'T-' + x.cardconnect_transaction.id,
                        type: 'text',
                        value: x.cardconnect_transaction.id
                    },
                    {
                        label: moment(+x.cardconnect_transaction.time).format('MM/DD/YYYY'),
                        type: 'text',
                        value: +x.cardconnect_transaction.time
                    }
                ],
                [
                    {label: moment(+x.time).format('MM/DD/YYYY'), type: 'text', value: x.time},
                    {label: moment(+x.time).format('hh:mm:ss A'), type: 'text', value: x.time}
                ],
                [
                    {label: `${new Intl.NumberFormat('en-US',
                    { style: 'currency', currency: 'USD' }
                  ).format(x.items.sumBy((y: any) => y.amount))}`, type: 'text', value: x.items.sumBy((y: any) => x.amount).toFixed(2)}
                ]
            ];

            result.menuOpened = false;
            result.data = x;
            return result;
        }
            )
            setRows(temp);
            evt?.complete();
            return true;
        } catch (ex) {
            // console.log('ex', ex.message);
            evt?.complete();
            if (!rows)
                setRows([]);
            setOpen(true);
            setType('alert')
            setTitle('Error');
            setMessage(ex.message)
            return false;
        }
    }

    const [effectCalled, setEffectCalled] = useState(false);
 
    useEffect(() => {


        // console.log('here')

        setValidResend((email.trim().length == 0 ? phone.length == 12 : (Helper.Validators.IsValidEmail(email) && (phone.length == 0 || phone.length == 12) )));

    }, [email, phone, name])





    const columns: IColumn[] = [
        {
            label: 'INVOICE #',
            orderBy: 'DESC',
            active: false,
        },
        {
            label: 'ORDER REFERENCE',
            orderBy: 'DESC',
            active: false,
            hideOnSmall: true
        },
        {
            label: 'STATUS',
            orderBy: 'DESC',
            active: false,
        },
        {
            label: 'PAYMENT',
            orderBy: 'DESC',
            active: false,
            hideOnSmall: true
        },
        {
            label: 'TIME',
            orderBy: 'DESC',
            active: false,
            hideOnSmall: true
        },
        {
            label: 'AMOUNT',
            orderBy: 'DESC',
            active: false,
        }
    ]


    const showDetail = (obj: any) => {
        let temp = {...obj};

      
        

        if (!temp.cardconnect_transaction)
            delete temp.cardconnect_transaction;
        else
            delete temp.cardconnect_transaction.__typename;

        delete temp.business;

        

        temp.total_amount = new Intl.NumberFormat('en-US',
        { style: 'currency', currency: 'USD' }).format(temp.items.sumBy((x: any) => x.amount));
        setTitle('Invoice Details');
        setMessage(JSON.stringify(temp, null, 2));
        setType('alert');
        setOpen(true);
    }
    const menuOptions: IMenuOption[] = [
        {
            icon: Helper.Icons.IconDetails,
            label: 'See Invoice Details',
            action: (obj: any) => {
                
                showDetail(obj);
            }
        },
        {
            icon: Helper.Icons.IconCreateInvoice,
            label: 'Send Invoice',
            condition: (obj => obj.cardconnect_transaction == null),
            action: (obj: any) => {
                setResend({
                    open: true,
                    isinvoice: true,
                    id: obj.id,
                    data: obj
                });
                setEmail(obj.email);
                setPhone(obj.phone);
                setName(obj.name);
                setNote(obj.note);

            }
        },
        {
            icon: Helper.Icons.IconCreateInvoice,
            label: 'Send Receipt',
            condition: (obj => obj.cardconnect_transaction != null),
            action: (obj: any) => {
                setResend({
                    open: true,
                    isinvoice: false,
                    id: obj.id,
                    data: obj
                });
                
                setEmail(obj.email);
                setPhone(obj.phone);
                setName(obj.name);
                setNote(obj.note);


            }
        },

        {
            icon: Helper.Icons.IconDelete,
            label: 'Delete Invoice',
            condition: (obj => obj.cardconnect_transaction == null),
            action: (obj: any) => {
                
                setOpen(true);
                setMessage('Are you sure you want to delete this invoice?');
                setTitle('Delete Invoice INV-' + obj.id);
                setType('confirmation')
                setReference({data: obj, key: 'delete'})
            }
        }
    ]


    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [type, setType] = useState<'alert' | 'confirmation'>('alert')
    const [reference, setReference] = useState<any>();


    const [resend, setResend] = useState<{open: boolean, id: number, isinvoice: boolean, data: any}>({
        id: -1,
        isinvoice: false,
        open: false,
        data: null
    });


    const deleteInvoice = async (id: number) => {
        try {
            setLoading(true);
            let data = await GraphqlService.SendMutation(DELETE_INVOICE, {invoice_id: id});

            if (!await load())
                return setLoading(false);

            setLoading(false);
            setType('alert')
            setMessage(data.message);
            setTitle(data.success ? 'Message' : 'Error');
            setOpen(true)
        } catch (ex) {
            setLoading(false);
            setType('alert')
            setMessage(ex.message);
            setTitle('Error');
            setOpen(true);
        }
    }

    const handleConfirm = (reference: any) => {
        if (reference)
        {
            if (reference.key == 'delete')
                deleteInvoice(reference.data.id);
        }
    }


    const [validResend, setValidResend] = useState(false);

   


    const handleResend = async (evt: any) => {
        let inv = resend.data;
        let isinv = resend.isinvoice;
        
        setResend({
            open: false,
            data: null,
            id: -1,
            isinvoice: false
        });
        try {
            let obj = isinv ? {
                id: inv.id,
                customer_name: name,
                phone,
                email,
                note,
                order_reference: inv.order_reference,
                business_id: inv.business.id,
                business_name: inv.business.name,
                items: inv.items.map((x: any) => ({name: x.name, base_amount: +x.base_amount, tax_amount: +x.tax_amount, count: +x.count, amount: +x.amount})),
                token: inv.token
            } : {
                email,
                phone,
                transaction_id: inv.cardconnect_transaction.id
            }

            // console.log(JSON.stringify(obj));
            setLoading(true);
            let data = await GraphqlService.SendMutation(isinv ? RESEND_INVOICE : RESEND_RECEIPT, obj);
            await load();
            setLoading(false)
        } catch (ex) {
            setLoading(false);
            setTitle('Error');
            setMessage(ex.message);
            setType('alert');
            setOpen(true);

        }
    }

    const handlePropertyChanged = (value: string, key: string) => {
        if (key == 'phone')
            setPhone(Helper.Masks.ValidPhone(value));
        else if (key == 'email')
            setEmail(value);
        else if (key == 'name')
            setName(value);
        else
            setNote(value);
        // console.log('key', key);
    }

    return (
        <div className='charge-history-component'>
            {loading && <LoadSpinner />}
            <div>
                    {resend.open && <div className="modal">
                    <div className="modal-content">
                        <h4 className='form-title mb-3'>RESEND {resend.isinvoice ? '' : 'RECEIPT FOR'} INVOICE-{resend.id}</h4>
                        {resend.isinvoice && <div className='form-group mb-3'>
                            <label htmlFor="name">NAME</label>
                            <input id='name' name='name' value={name} onChange={(evt) => handlePropertyChanged(evt.target.value, 'name')} className='form-field' type="text"/>
                        </div>}
                        <div className='form-group mb-3'>
                            <label htmlFor="email">EMAIL*</label>
                            <input id='email' name='email' value={email} onChange={(evt) => handlePropertyChanged(evt.target.value, 'email')} className='form-field' type="email"/>
                        </div>
                         <div className='form-group mb-3'>
                            <label htmlFor="phone">PHONE*</label>
                            <input id='phone' name='phone' value={phone} onChange={(evt) => handlePropertyChanged(evt.target.value, 'phone')} className='form-field' type="text"/>
                        </div>
                        {resend.isinvoice && <div className='form-group mb-3'>
                            <label htmlFor="note">NOTE</label>
                            <textarea id='note' name='note' value={note} onChange={(evt) => handlePropertyChanged(evt.target.value, 'note')} className='form-field'/>
                        </div>}

                        <div className='d-flex justify-content-between mt-4'>
                            <button className='btn' onClick={(evt) => setResend({open: false, id: -1, isinvoice: false, data: null})}>CANCEL</button>
                            <button onClick={(evt) => handleResend(evt)} className='btn btn-orange' disabled={!validResend}>SEND</button>
                        </div>
                    </div>
                </div>}
                    
                    
                    <MessageBox onConfirmClicked={(reference) => handleConfirm(reference)} type={type} isOpen={open} setIsOpen={setOpen} message={message} title={title} reference={reference} />
                    <Table selectionType='range' onRowClicked={(obj) => showDetail(obj)} title='INVOICES'
                        onLoadRequested={(evt) => load(evt)}
                        columns={columns}
                        rows={rows} 
                        menuOptions={menuOptions}    
                    />
            </div>
        </div>
    )
}

export default InvoiceHistory;