import { gql } from "apollo-boost";

export const GET_INVOICE_BY_TOKEN = gql`
   query($token: String!) {
  get_transaction_invoice(token: $token) {
    id
    order_reference
    amount_remaining
    note
    invoice_items {
      name
      base_amount
      tax_amount
      count
      amount
      cardconnect_transaction {
        response {
          token
          binInfo {
            cardType
          }
        }
      }
    }
    business {
      id
      name
    }
    phone
    email
    name
    cardconnect_transactions {
      id
      time
    }
  }
}
`

export const SEND_INVOICE = gql`
    mutation ($order_reference: String!, $items: [itemInput!]!, $note: String, $customer_name: String!, $phone: String, $email: String) {
        create_transaction_invoice(input:{
            order_reference:$order_reference
            items: $items
            phone:$phone
            email: $email
            name:$customer_name
            note: $note
        }){
            id
        }
    }
`

export const GET_ALL_INVOICES = gql`
query ($start_date: String!, $end_date: String!){
  get_transaction_invoice_by_business(start_date:$start_date end_date:$end_date){
    id
    order_reference
            note

    time
    token
    items{
      name
      tax_amount
      tax_amount
      count
      amount
    }
    phone
    email
    name
    business{
      id
      name
    }
    cardconnect_transaction {
      id
      name
      time
    }
  }
}
`;  

export const RESEND_INVOICE = gql`
mutation ($id: Int! $order_reference: String!, $items: [itemInput!]!, $note: String, $customer_name: String!, $phone: String, $email: String $business_name:String! $business_id:Int!, $token: String!) {
    resend_transaction_invoice(input:{
    invoice:{
      id: $id
      order_reference: $order_reference
      items: $items
      phone:$phone
      email:$email
      name:$customer_name
      note:$note
      token: $token
    }
    business:{
      name:$business_name
      id:$business_id
    }
  }){
    success
    message
  }
}
`
