import { ApolloClient } from "apollo-client";

import { setContext } from "apollo-link-context";
import { createUploadLink } from "apollo-upload-client";

import crypto from 'crypto-js';

import { InMemoryCache } from "apollo-cache-inmemory";
import environments from "../environment/environment";


const getPlatform = (token: string) => {
    let secret = token.substring(token.length - 8);
    let time = new Date().getTime().toString();
    return crypto.AES.encrypt(time, secret).toString();
}

const link = createUploadLink({
    uri: environments.SERVER_LINK + '/graphql'
  });

  const authLink = setContext((_, { headers }) => {
    
    // console.log(platform)
    // const obj = {
    //   [localStorage.getItem('driver_token') ? 'Driver' : 'Authentication']: token
    // };
    // console.log(obj);
    return {
      headers: {
        ...headers,
        "Authentication": localStorage.getItem('token') || '',
        "Platform": getPlatform(localStorage.getItem('token') || '')
      }
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(link as any),
    cache: new InMemoryCache({
      addTypename: false
    })

  });



// export const createClient = () => new ApolloClient({
//     link: new creteUp((operation, forward) => {
//         operation.setContext({
//             headers: {
//                 "Authentication": localStorage.getItem("token") || '',
//                 "Platform": getPlatform(localStorage.getItem('token') || '')
//             }
//         });
//         return forward(operation);
//     }).concat(
//         new HttpLink({
//             uri: environments.SERVER_LINK + '/graphql', // Server URL
//         })
//     ),
//     cache: new InMemoryCache()
// });

export default client;
